import React from 'react'

export default function checkout() {
  return (
    <div class="main">
	<div>
    	<img src="./images/payumoney.png" />
    </div>
    <div>
    	<h3>NODE.JS BOLT Kit</h3>
    </div>
	<form action="#" id="payment_form">
    <input type="hidden" id="udf5" name="udf5" value="BOLT_KIT_NODE_JS" />
    <input type="hidden" id="surl" name="surl" value="http://localhost:3000/response.jsx" />
    <div class="dv">
    <span class="text"><label>Merchant Key:</label></span>
    <span><input type="text" id="key" name="key" placeholder="Merchant Key" value="" /></span>
    </div>
    
    <div class="dv">
    <span class="text"><label>Merchant Salt:</label></span>
    <span><input type="text" id="salt" name="salt" placeholder="Merchant Salt" value="" /></span>
    </div>
    
    <div class="dv">
    <span class="text"><label>Transaction/Order ID:</label></span>
    <span><input type="text" id="txnid" name="txnid" placeholder="Transaction ID" value="<%= orderid %>" /></span>
    </div>
    
    <div class="dv">
    <span class="text"><label>Amount:</label></span>
    <span><input type="text" id="amount" name="amount" placeholder="Amount" value="6.00" /></span>    
    </div>
    
    <div class="dv">
    <span class="text"><label>Product Info:</label></span>
    <span><input type="text" id="pinfo" name="pinfo" placeholder="Product Info" value="P01,P02" /></span>
    </div>
    
    <div class="dv">
    <span class="text"><label>First Name:</label></span>
    <span><input type="text" id="fname" name="fname" placeholder="First Name" value="" /></span>
    </div>
    
    <div class="dv">
    <span class="text"><label>Email ID:</label></span>
    <span><input type="text" id="email" name="email" placeholder="Email ID" value="" /></span>
    </div>
    
    <div class="dv">
    <span class="text"><label>Mobile/Cell Number:</label></span>
    <span><input type="text" id="mobile" name="mobile" placeholder="Mobile/Cell Number" value="" /></span>
    </div>
    
    <div class="dv">
    <span class="text"><label>Hash:</label></span>
    <span><input type="text" id="hash" name="hash" placeholder="Hash" value="" /></span>
    </div>
    <div id="alertinfo" class="dv"></div>
    
    <div><input type="submit" value="Pay" onclick="launchBOLT(); return false;" /></div>
	</form>
</div>
  )

  ('#payment_form').bind('keyup blur', function(){
	const ajax = ({
          url: 'http://localhost:3005',
          type: 'post',
          data: JSON.stringify({ 
            key: ('#key').val(),
			salt: ('#salt').val(),
			txnid: ('#txnid').val(),
			amount: ('#amount').val(),
		    pinfo: ('#pinfo').val(),
            fname: ('#fname').val(),
			email: ('#email').val(),
			mobile: ('#mobile').val(),
			udf5: ('#udf5').val()
          }),
		  contentType: "application/json",
          dataType: 'json',
          success: function(json) {            
			('#hash').val(json);            
          }
        }); 
});



function launchBOLT()
{
    const luunch=luunch 
	const bolt= 
    ({
	key: ('#key').val(),
	txnid: ('#txnid').val(), 
	hash: ('#hash').val(),
	amount: ('#amount').val(),
	firstname: ('#fname').val(),
	email: ('#email').val(),
	phone: ('#mobile').val(),
	productinfo: ('#pinfo').val(),
	udf5: ('#udf5').val(),
	surl : ('#surl').val(),
	furl: ('#surl').val()
    },{ responseHandler: function(BOLT){
	console.log( BOLT.response.txnStatus );		
	if(BOLT.response.txnStatus != 'CANCEL')
	{
		//Salt is passd here for demo purpose only. For practical use keep salt at server side only.
		var fr = '<form action=\"'+('#surl').val()+'\" method=\"post\">' +
		'<input type=\"hidden\" name=\"key\" value=\"'+BOLT.response.key+'\" />' +
		'<input type=\"hidden\" name=\"salt\" value=\"'+('#salt').val()+'\" />' +
		'<input type=\"hidden\" name=\"txnid\" value=\"'+BOLT.response.txnid+'\" />' +
		'<input type=\"hidden\" name=\"amount\" value=\"'+BOLT.response.amount+'\" />' +
		'<input type=\"hidden\" name=\"productinfo\" value=\"'+BOLT.response.productinfo+'\" />' +
		'<input type=\"hidden\" name=\"firstname\" value=\"'+BOLT.response.firstname+'\" />' +
		'<input type=\"hidden\" name=\"email\" value=\"'+BOLT.response.email+'\" />' +
		'<input type=\"hidden\" name=\"udf5\" value=\"'+BOLT.response.udf5+'\" />' +
		'<input type=\"hidden\" name=\"mihpayid\" value=\"'+BOLT.response.mihpayid+'\" />' +
		'<input type=\"hidden\" name=\"status\" value=\"'+BOLT.response.status+'\" />' +
		'<input type=\"hidden\" name=\"hash\" value=\"'+BOLT.response.hash+'\" />' +
		'</form>';
		// var form = jQuery(fr);
		// jQuery('body').append(form);								
		// form.submit();
	}
},
	catchException: function(BOLT){
 		alert( BOLT.message );
	}
})}}




