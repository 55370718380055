// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import Footer from "./Footer";
// export default function Contact() {

//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [subject, setSubject] = useState('');
//   const [message, setMessage] = useState('');
//   const [formErrors, setFormErrors] = useState({});
//   const validate = () => {

//     const errors = {};
//     if (!name) {
//       errors.name = "Please Enter Name";
//     }
//     // if(!service_image2){
//     if (!email) {
//       errors.email = "Please Enter Email";
//     }
//     // }
//     if (!subject) {
//       errors.subject = "Please Enter subject";
//     }
//     if (!message) {
//       errors.message = "Please Enter your message";
//     }

//     return errors;
//   };

//   const submitHandle = async (e) => {
//     e.preventDefault()
//     setFormErrors(validate())
//     if (name != "" && email != "" && subject != "" && message != "") {
//       try {
//         const res = await axios.post("/contact",
//           {
//             name,
//             email,
//             subject,
//             message
//           });
//         alert(res.data)

//       }
//       catch (err) {
//         alert(err)
//       }
//     }
//   }


//   return (
//     <>
//       <div class="my-3">
//         <section id="breadcrumbs" class="breadcrumbs">
//           <div class="container">
//             <div class="d-flex justify-content-between align-items-center">
//               <h2>Contact</h2>
//               <ol>
//                 <li> You are Here / </li>
//                 <li class="nav-item">
//                   <a class="nav-link" href="/"> Home </a>
//                 </li>
//                 <li>Contact</li>
//               </ol>
//             </div>
//           </div>
//         </section>

//         <section id="contact" class="contact">
//           <div class="container">
//             <div>

//               {/* <p> <a href="https://www.google.com/search?sa=X&biw=1360&bih=657&tbs=lf:1,lf_ui:2&tbm=lcl&q=dwarka+shankaracharya+math&rflfq=1&num=10&rldimm=733860904299665943&ved=2ahUKEwi1neyEn7r8AhVYHbcAHXQfBKoQu9QIegQIFBAJ#rlfi=hd:;si:;mv:[[22.2446745,68.9685173],[22.237019399999998,68.9677466]]" target="_blank">
//         <img src="assets/img/slide/map.jpg" alt="Dwarka" width="100%" height="270"/></a></p>  */}

//               <a href="https://goo.gl/maps/gjVtSpL5RFv2ugws6" target={"_blank"}><iframe style={{width:"1100px",height:"400px"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1846.529832824097!2d68.96626595143485!3d22.237814864579796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39569c3ac6424e11%3A0xa2f329ab3541a17!2sShrishardapeetham%20Dwarka!5e0!3m2!1sen!2sus!4v1676534019969!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></a>
//             </div>

//             <div class="row mt-5">
//               <div class="col-lg-4">
//                 <div class="info">
//                   <div class="address">
//                     <i class="bi bi-geo-alt"></i>
//                     <h4>Location:</h4>
//                     <p>A108 Adam Street, New York, NY 535022</p>
//                   </div>

//                   <div class="email">
//                     <i class="bi bi-envelope"></i>
//                     <h4>Email:</h4>
//                     <p>info@example.com</p>
//                   </div>

//                   <div class="phone">
//                     <i class="bi bi-phone"></i>
//                     <h4>Call:</h4>
//                     <p>+1 5589 55488 55</p>
//                   </div>
//                 </div>
//               </div>

//               <div class="col-lg-8 mt-5 mt-lg-0">
//                 <form>
//                   {/* <form action="forms/contact.php" method="post" role="form" class="php-email-form"> */}
//                   <div class="row">
//                     <div class="col-md-6 form-group">
//                       <input type="text" id="name" name="name" class="form-control" placeholder="Your Name"
//                         onChange={(e) => setName(e.target.value)}
//                       ></input>
//                       {/* <input type="text" className="form-control" defaultValue={service_name} id="service_name" onChange={(e)=>setService_name(e.target.value)} /> */}
//                       <p style={{ color: "red" }}>{formErrors.name}</p>

//                     </div>
//                     <div class="col-md-6 form-group">
//                       <input
//                         type="text"
//                         class="form-control"
//                         name="email"
//                         id="email"
//                         placeholder="Your Email"
//                         onChange={(e) => setEmail(e.target.value)}
//                       />
//                       <p style={{ color: "red" }}>{formErrors.email}</p>

//                     </div>
//                   </div>
//                   <div class="form-group mt-3">
//                     <input
//                       type="text"
//                       class="form-control"
//                       // name="subject"
//                       id="subject"
//                       placeholder="Subject"
//                       onChange={(e) => setSubject(e.target.value)}
//                     />
//                     <p style={{ color: "red" }}>{formErrors.subject}</p>
//                   </div>
//                   <div class="form-group mt-3">
//                     <textarea
//                       class="form-control"
//                       name="message"
//                       rows="5"
//                       placeholder="Message"
//                       onChange={(e) => setMessage(e.target.value)}
//                     ></textarea>
//                     <p style={{ color: "red" }}>{formErrors.message}</p>
//                   </div> <br></br>
//                   {/* <div class="my-3">
//                 <div class="loading">Loading</div>
//                 <div class="error-message"></div>
//                 <div class="sent-message">
//                   Your message has been sent. Thank you!
//                 </div>
//               </div> */}
//                   <div class="text-center">
//                     {/* <button type="submit">Send Message</button>  */}
//                     <button type="submit" onClick={submitHandle}> Submit </button>
//                     {/* <button onClick={refresh}>Refresh</button> */}
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>

//     </>

//   );
// }

import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
// export default function Contact() {

//   const [name,setName]=useState('');
//   const [email,setEmail]=useState('');
//   const [subject,setSubject] = useState('');
//   const [message,setMessage]=useState('');
//   const [formErrors, setFormErrors] = useState({});
//   const validate = () => {

//     const errors = {};
//     if (!name) {
//       errors.name = "Please Enter Name";
//     }
//     // if(!service_image2){
//     if (!email) {
//       errors.email = "Please Enter Email";
//     }
//   // }
//     if (!subject) {
//       errors.subject = "Please Enter subject";
//     }
//     if (!message) {
//       errors.message = "Please Enter your message";
//     }

//     return errors;
//   };

//   const submitHandle=async(e)=>{
//     e.preventDefault()
//     setFormErrors(validate())
//         if(name !="" && email !="" && subject !="" && message !="")
//         {
//         try{
//              const res=await axios.post("/contact",
//       {
//         name,
//         email,
//         subject,
//         message
//       });
//       alert(res.data)
//       setName("");
//       setEmail("");
//       setSubject("");
//       setMessage("");

//     }
//     catch(err)
//     {
//       alert(err)
//     }
//   }
// }


//   return (

//     <div class="my-3">
//        <section id="breadcrumbs" class="breadcrumbs">
//           <div class="container">
//             <div class="d-flex justify-content-between align-items-center">
//               <h2>Contact</h2>
//               <ol>
//                 <li> You are Here / </li>
//                   <li class="nav-item">
//                     <a class="nav-link" href ="/"> Home </a>  
//                   </li>
//                 <li>Contact</li>
//               </ol>
//             </div>
//           </div>
//         </section>

//     <section id="contact" class="contact">
//         <div class="container">
//         <div>

//         <a href="https://goo.gl/maps/gjVtSpL5RFv2ugws6" target={"_blank"}><iframe style={{width:"1100px",height:"400px"}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1846.529832824097!2d68.96626595143485!3d22.237814864579796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39569c3ac6424e11%3A0xa2f329ab3541a17!2sShrishardapeetham%20Dwarka!5e0!3m2!1sen!2sus!4v1676534019969!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></a>
//         </div>

//         <div class="row mt-5">
//           <div class="col-lg-4">
//             <div class="info">
//               <div class="address">
//                 <i class="bi bi-geo-alt"></i>
//                 <h4>Location:</h4>
//                 <p>A108 Adam Street, New York, NY 535022</p>
//               </div>

//               <div class="email">
//                 <i class="bi bi-envelope"></i>
//                 <h4>Email:</h4>
//                 <p>info@example.com</p>
//               </div>

//               <div class="phone">
//                 <i class="bi bi-phone"></i>
//                 <h4>Call:</h4>
//                 <p>+1 5589 55488 55s</p>
//               </div>
//             </div>
//           </div>

//           <div class="col-lg-8 mt-5 mt-lg-0">
//             <form>
//               {/* <form action="forms/contact.php" method="post" role="form" class="php-email-form"> */}
//               <div class="row">
//                 <div class="col-md-6 form-group">
//                 <input type="text" id="name" name="name" 
//                 value={name}
//                 class="form-control" placeholder="Your Name" 
//                 onChange={(e)=>setName(e.target.value)}
//                 ></input>
//                 {/* <input type="text" className="form-control" defaultValue={service_name} id="service_name" onChange={(e)=>setService_name(e.target.value)} /> */}
//                 <p style={{ color: "red" }}>{formErrors.name}</p>

//                  </div>
//                 <div class="col-md-6 form-group">
//                    <input
//                     type="text"
//                     class="form-control"
//                     name="email"
//                     id="email"
//                     value={email}
//                     placeholder="Your Email"
//                     onChange={(e)=>setEmail(e.target.value)}

//                     />
//                      <p style={{ color: "red" }}>{formErrors.email}</p>

//                 </div>
//               </div>
//               <div class="form-group mt-3">
//                 <input
//                   type="text"
//                   class="form-control"
//                   // name="subject"
//                   id="subject"
//                   value={subject}
//                   placeholder="Subject"
//                   onChange={(e)=>setSubject(e.target.value)}
//                   />
//                   <p style={{ color: "red" }}>{formErrors.subject}</p>
//               </div>
//               <div class="form-group mt-3">
//                 <textarea
//                   class="form-control"
//                   name="message"
//                   rows="5"
//                   value={message}
//                   placeholder="Message"
//                   onChange={(e)=>setMessage(e.target.value)}
//                   ></textarea>
//                    <p style={{ color: "red" }}>{formErrors.message}</p>
//               </div> <br></br>
//               {/* <div class="my-3">
//                 <div class="loading">Loading</div>
//                 <div class="error-message"></div>
//                 <div class="sent-message">
//                   Your message has been sent. Thank you!
//                 </div>
//               </div> */}
//               <div class="text-center">
//                  {/* <button type="submit">Send Message</button>  */}
//                  <button type="submit" onClick={submitHandle}> Submit </button>
//                  {/* <button onClick={refresh}>Refresh</button> */}
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//       </section>
//     </div>

//   );
// }
export default function Contact() {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const validate = () => {
    const re = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
    const errors = {};
    if (!name) {
      errors.name = "Please Enter Name";
    }
    // if(!service_image2){
    if (email.length == 0) {
      errors.email = "Please Enter Email";
    } else if (!re.test(String(email).toLowerCase())) {
      errors.email = "email not valid";
    }
    // if (email = !(/^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/.test(email)))
    // {
    //   errors.email = "Email is not valid";
    // }

    if (!subject) {
      errors.subject = "Please Enter subject";
    }
    if (!message) {
      errors.message = "Please Enter your message";
    }

    return errors;
  };

  const submitHandle = async (e) => {
    e.preventDefault()
    setFormErrors(validate());
    // console.log(email.length)
    // console.log(validate())
    const dataReadyToSend = validate();

    // coment delete karavi niche ni 

    // console.log(Object.keys(dataReadyToSend).length)
    // if (Object.keys(dataReadyToSend).length == 0) {
    //   if (name != "" && email != "" && subject != "" && message != "") {
    //     try {
    //       const res = await axios.post("/contact",
    //         {
    //           name,
    //           email,
    //           subject,
    //           message
    //         });
    //       alert(res.data)
    //       setName("");
    //       setEmail("");
    //       setSubject("");
    //       setMessage("");

    //     }
    //     catch (err) {
    //       alert(err)
    //     }
    //   }
    //   console.log("data ready to send")
    // } else {
    //   console.log("data not ready to send")
    // }
  }


  return (

  

    <div class="my-3">
      <section id="breadcrumbs" class="breadcrumbs">
      <div>
        <Helmet>
           <title>Contact Us | Shri Dwarka Shardapeeth</title>
            {/* <meta
                name="description"
                content="Get lyrics of every music for free"
            /> */}
        </Helmet>
        </div>

        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <h2>Contact</h2>
            <ol>
              <li> You are Here / </li>
              <li class="nav-item">
                <a class="nav-link" href="/"> Home </a>
              </li>
              <li>Contact</li>
            </ol>
          </div>
        </div>
      </section>

      <section id="contact" class="contact">
        <div class="container">
          <div>
            <a href="https://goo.gl/maps/gjVtSpL5RFv2ugws6" target={"_blank"}><iframe style={{ width: "100%", height: "400px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1846.529832824097!2d68.96626595143485!3d22.237814864579796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39569c3ac6424e11%3A0xa2f329ab3541a17!2sShrishardapeetham%20Dwarka!5e0!3m2!1sen!2sus!4v1676534019969!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></a>
          </div>

          <div class="row mt-5">
            <div class="col-lg-4">
              <div class="info">
                <div class="address">
                  <i class="bi bi-geo-alt"></i>
                  <h4>Location:</h4>
                  <p>Shrishardapeetham Dwarka, Dwarka, Gujarat 361335</p>
                </div>

                <div class="email">
                  <i class="bi bi-envelope"></i>
                  <h4>Email:</h4>
                  <p>shardapithmathdwarka@gmail.com</p>
                </div>

                <div class="phone">
                  <i class="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p> 7587525263,<br></br> 8770780477,<br></br>9427275545</p>
                </div>
                {/* <a></a><img src="assets/img/1.jpg" alt=" "  ></img> */}
              </div>
            </div>

            <div class="col-lg-8 mt-5 mt-lg-0">
              <form>
                {/* <form action="forms/contact.php" method="post" role="form" class="php-email-form"> */}
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input type="text" id="name" name="name"
                      value={name}
                      class="form-control" placeholder="Your Name"
                      onChange={(e) => setName(e.target.value)}
                    ></input>
                    {/* <input type="text" className="form-control" defaultValue={service_name} id="service_name" onChange={(e)=>setService_name(e.target.value)} /> */}
                    <p style={{ color: "red" }}>{formErrors.name}</p>

                  </div>
                  <div class="col-md-6 form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="email"
                      id="email"
                      value={email}
                      placeholder="Your Email"
                      onChange={(e) => setEmail(e.target.value)}

                    />
                    <p style={{ color: "red" }}>{formErrors.email}</p>

                  </div>
                </div>
                <div class="form-group mt-3">
                  <input
                    type="text"
                    class="form-control"
                    // name="subject"
                    id="subject"
                    value={subject}
                    placeholder="Subject"
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <p style={{ color: "red" }}>{formErrors.subject}</p>
                </div>
                <div class="form-group mt-3">
                  <textarea
                    class="form-control"
                    name="message"
                    rows="5"
                    value={message}
                    placeholder="Message"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <p style={{ color: "red" }}>{formErrors.message}</p>
                </div> <br></br>
                {/* <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div> */}
                <div class="text-center">
                  {/* <button type="submit">Send Message</button>  */}
                  <button type="submit" onClick={submitHandle}> Submit </button>
                  {/* <button onClick={refresh}>Refresh</button> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>

  );
}