// import React from 'react';
// import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';
// import Footer from './Footer';

// export default function Slider() {

//   return (

//     // <main id="main">

//   //      <section id="hero">
//   //   <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">
//   //    <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>
//   //     <div class="carousel-inner" role="listbox">
//   //        <div class="carousel-item active" style={{backgroundImage: `url("assets/img/slide/slide-1.jpg")`}}>
//   //            <div class="carousel-container">
//   //           <div class="container">
//   //             <h2 class="animate__animated animate__fadeInDown">Welcome to <span>Sailor</span></h2>
//   //             <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
//   //             <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
//   //           </div>
//   //         </div> 
//   //       </div>


//   //       <div class="carousel-item" style={{backgroundImage:`url("assets/img/slide/slide-2.jpg")`,height : 50 }}>
//   //         <div class="carousel-container">
//   //           <div class="container">
//   //             <h2 class="animate__animated animate__fadeInDown">Lorem Ipsum Dolor</h2>
//   //             <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
//   //             <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
//   //           </div>
//   //         </div>
//   //       </div>


//   //       <div class="carousel-item" style={{backgroundImage: `url("assets/img/slide/slide-3.jpg")`}}>
//   //         <div class="carousel-container">
//   //           <div class="container">
//   //             <h2 class="animate__animated animate__fadeInDown">Sequi ea ut et est quaerat</h2>
//   //             <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
//   //             <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
//   //           </div>
//   //         </div>
//   //       </div>

//   //     </div>

//   //     <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
//   //       <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
//   //     </a>

//   //     <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
//   //       <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
//   //     </a>

//   //   </div>
//   // </section>
//  <>
//   <section id="hero">
//     <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">

//       <ol class="carousel-indicators" id="hero-carousel-indicators"><li data-bs-target="#heroCarousel" data-bs-slide-to="0" class="active" aria-current="true"></li><li data-bs-target="#heroCarousel" data-bs-slide-to="1" class=""></li><li data-bs-target="#heroCarousel" data-bs-slide-to="2" class=""></li></ol>

//       <div class="carousel-inner" role="listbox">


//       <div class="carousel-item active" style={{backgroundImage: `url("assets/img/slide/slide-1.jpg")`}}>
//           <div class="carousel-container">
//             <div class="container">
//               <h2 class="animate__animated animate__fadeInDown">Welcome to <span>Sailor</span></h2>
//               <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
//               {/* <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a> */}
//             </div>
//           </div>
//         </div>

//         <div class="carousel-item active" style={{backgroundImage: `url("assets/img/slide/slide-6.png")`}}>
//         {/* <img src="assets/img/slide/slide-5.jpg"  width="70%" height="660%"/> */}
//         {/* style={{backgroundImage: `url("assets/img/slide/slide-6.png")`}}> */}
//           <div class="carousel-container">
//             <div class="container">
//               {/* <h2 class="animate__animated animate__fadeInDown">Lorem Ipsum Dolor</h2>
//               <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p> */}
//               {/* <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a> */}
//             </div>
//           </div>
//         </div>


//         {/* <div class="carousel-item active" style={{backgroundImage: `url("assets/img/slide/slide-18.jpg")`,
//         backgroundsize: "10px", width: "10px;", height: "10px;",backgroundColor:"red"}}>
//           <div class="carousel-container">
//             <div class="container">
//               <h2 class="animate__animated animate__fadeInDown">Sequi ea ut et est quaerat</h2>
//               <p class="animate__animated animate__fadeInUp">Ut velit est quam dolor ad a aliquid qui aliquid. Sequi ea ut et est quaerat sequi nihil ut aliquam. Occaecati alias dolorem mollitia ut. Similique ea voluptatem. Esse doloremque accusamus repellendus deleniti vel. Minus et tempore modi architecto.</p>
//               <a href="#about" class="btn-get-started animate__animated animate__fadeInUp scrollto">Read More</a>
//             </div>
//           </div>
//         </div> */}
//         <div class="carousel-item active">
//         <div class="carousel-container">

//         <img src="assets/img/slide/slide-18.jpg"  width="2300%" height="560"/>
//         </div>
//         </div>

//       </div>

//       <a class="carousel-control-prev" href="#heroCarousel" role="button" data-bs-slide="prev">
//         <span class="carousel-control-prev-icon bi bi-chevron-left" aria-hidden="true"></span>
//       </a>

//       <a class="carousel-control-next" href="#heroCarousel" role="button" data-bs-slide="next">
//         <span class="carousel-control-next-icon bi bi-chevron-right" aria-hidden="true"></span>
//       </a>

//     </div>
//   </section>
//  </>

//     // </main>
//   );
// }
// import React from 'react';
// import {initLightboxJS} from 'lightbox.js-react'
// import 'lightbox.js-react/dist/index.css'
import React, { Component } from 'react'
import 'lightbox.js-react/dist/index.css'
import {SlideshowLightbox,initLightboxJS} from 'lightbox.js-react'


export default function Slider() {
  return (
//     <div>
//       <h1> hello </h1>
//       <header id="header" class="d-flex flex-column justify-content-center"><nav id="navbar" class="navbar nav-menu"><ul><li><a href="#hero" class="nav-link scrollto active"><i class="bx bx-home"></i> <span>Home</span></a></li><li><a href="#about" class="nav-link scrollto"><i class="bx bx-user"></i> <span>About</span></a></li><li><a href="#resume" class="nav-link scrollto"><i class="bx bx-file-blank"></i> <span>Resume</span></a></li><li><a href="#portfolio" class="nav-link scrollto"><i class="bx bx-book-content"></i> <span>Portfolio</span></a></li><li><a href="#services" class="nav-link scrollto"><i class="bx bx-server"></i> <span>Services</span></a></li><li><a href="#contact" class="nav-link scrollto"><i class="bx bx-envelope"></i> <span>Contact</span></a></li></ul></nav></header>
//       <div class="vc_column-inner us_custom_9ae4113c">
//       <div class="wpb_wrapper">
//       <div class="w-video align_none ratio_16x9">
//          <div class="w-video-h">
//             <iframe title="Shrimad Bhagavat Katha | Pujya Bhaishri Rameshbhai Oza | Baroda, Gujarat." width="640" height="360" src="https://www.youtube.com/embed/videoseries?list=PL8u7Wk6wN-0BrQF4KvbKtUv77xE3ajg_Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe></div>
//          <div class="w-video-icon" style="font-size:1.5rem;background:rgba(0,0,0,0.5);color:#fff"></div>
//       </div>
//       <div class="wpb_text_column us_custom_8c42ed40">
//          <div class="wpb_wrapper">
//             <h6 style="text-align: center;"><span style="color: #ffffff;"><b><span style="color: #ffffff;">Join Shrimad Bhagavat Katha by Pujya Bhaishri in Vadodara from Jan 5th-12th LIVE on Sandipani.TV</span><br></br>
//                </b></span>
//             </h6>
//          </div>
//       </div>
//    </div>
// </div> 
//     </div>
<div>
      <section id="breadcrumbs" class="breadcrumbs1">
      <section id="contact" class="contact1">
      <h1>Click on the image!</h1>
    {/* <img class="lightbox" src="assets/img/slide/bhumipujan-shardapith-dwarka-pujan.jpg" alt="Bhumipujan Shardapith Dwarka Pujan" style={{hight:"50%",width:"30%",marginBottom:"20px"}}  /> */}
    <img class="lightbox" src="http://eskipaper.com/images/photos-1.jpg" />
    <img class="lightbox" src="https://www.istockphoto.com/resources/images/PhotoFTLP/img_67920257.jpg" />
    <img class="lightbox" src="https://static.pexels.com/photos/301584/pexels-photo-301584.jpeg" />
    <img class="lightbox" src="https://static.pexels.com/photos/46710/pexels-photo-46710.jpeg" />
    <img class="lightbox" src="http://eskipaper.com/images/photos-1.jpg" />
    <img class="lightbox" src="https://www.istockphoto.com/resources/images/PhotoFTLP/img_67920257.jpg" />
    <img class="lightbox" src="https://static.pexels.com/photos/301584/pexels-photo-301584.jpeg" />
    <img class="lightbox" src="https://static.pexels.com/photos/46710/pexels-photo-46710.jpeg" />ss
    {/* <img class="lightbox" src="http://eskipaper.com/images/photos-1.jpg" />
    <img class="lightbox" src="https://www.istockphoto.com/resources/images/PhotoFTLP/img_67920257.jpg" />
    <img class="lightbox" src="https://static.pexels.com/photos/301584/pexels-photo-301584.jpeg" />
    <img class="lightbox" src="https://static.pexels.com/photos/46710/pexels-photo-46710.jpeg" />
          */}

{/* <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto">
    <img className="w-full rounded" src="https://source.unsplash.com/pAKCx4y2H6Q/1400x1200"  style={{height:"100px"}}/>
    <img className="w-full rounded" src="https://source.unsplash.com/AYS2sSAMyhc/1400x1200" />  
    <img className="w-full rounded" src="https://source.unsplash.com/Kk8mEQAoIpI/1600x1200" />
    <img className="w-full rounded" src="https://source.unsplash.com/HF3X2TWv1-w/1600x1200" />
</SlideshowLightbox>  */}
{/* <SlideshowLightbox className='container grid grid-cols-3 gap-2 mx-auto' showThumbnails={true}>
  <img className='w-full rounded' src='https://images.pexels.com/photos/580151/pexels-photo-580151.jpeg' />
  <img className='w-full rounded' src='https://images.pexels.com/photos/13996896/pexels-photo-13996896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />  
  <img className='w-full rounded' src='https://images.pexels.com/photos/13208323/pexels-photo-13208323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' />
 
</SlideshowLightbox>  */}
{/* <div class='row'>
<SlideshowLightbox className='container grid grid-cols-3 gap-2 mx-auto' showThumbnails={true}>

      <img  src="assets/img/slide/bhumipujan-shardapith-dwarka-pujan.jpg" alt="Bhumipujan Shardapith Dwarka Pujan" style={{hight:"50%",width:"30%",marginBottom:"20px"}}  />
       <img  src="assets/img/slide/bhumipujan-shardapith-dwarka-2.jpg" alt="Bhumipujan Shardapith Dwarka" style={{hight:"50%",width:"30%",marginBottom:"20px"}}  />
       <img src="assets/img/slide/bhumipujan-shardapith-dwarka.jpg" alt="Bhumi Pujan Shree Shardapith Dwarka" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
       


<img className='col-md-4' src="assets/img/slide/amit-1.jpeg" alt="Manniya Amitkumar" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img className='col-md-4' src="assets/img/slide/shardapith-visit-dwarka-2.jpg" alt="Shardapith Visit Dwarka" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img className='col-md-4' src="assets/img/slide/amit-3.jpeg" alt="Manniya Amitkumar" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />


<img className='col-md-4' src="assets/img/slide/odhav-ahm-visit.jpg" alt="Forth slide" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img className='col-md-4' src="assets/img/slide/pujan-shardapith-dwaraka.jpg" alt="Five slide" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img className='col-md-4' src="assets/img/slide/shardapith-visit-dwarka.jpg" alt="Five slide" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        </SlideshowLightbox>

</div> */}

{/* <div className='container grid grid-cols-3 gap-2 mx-auto'> */}



    {/* <img className="w-full rounded" src="https://source.unsplash.com/pAKCx4y2H6Q/400x200"  style={{height:"100px"}}/>
    <img className="w-full rounded" src="https://source.unsplash.com/AYS2sSAMyhc" />  
    <img className="w-full rounded" src="https://source.unsplash.com/Kk8mEQAoIpI/1600x1200" />
    <img className="w-full rounded" src="https://source.unsplash.com/HF3X2TWv1-w/1600x1200" /> */}
{/* </div>   */}
</section>
</section>
</div> 
  )
}
