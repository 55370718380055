import React from 'react';
// import Footer from './Footer';
import { Helmet } from "react-helmet";

function GlobalNetwork() {
    return (
        <>
            <div id="globalnetwork">
            <div>
                    <Helmet>
            
                        <title>Global Network| Shree Shardapith Dwaraka,Gujarat </title>
                        {/* <meta
                            name="description"
                            content="Get lyrics of every music for free"
                        /> */}
                    </Helmet>
                    </div>
                {/* <div className='city2'>
                <img style={{width:"100%"}} src="assets/img/slide/word-network.jpeg" alt="global network" />
                
                </div> */}
                <div class="text-center">
                {/* <div className='city2'> */}
                <img style={{width:"80%",marginTop:"30px"}} src="assets/img/slide/word-network.jpeg" alt="global network" />
                
                {/* </div> */}
                {/* <img style={{width:"100%",marginTop:"30px"}} src="assets/img/slide/word-network.jpeg" alt="global network" /> */}
              <h1>Global Network</h1>
            </div>
                
                <div className='city'>
                    
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>ज्योतिर्मठ, उत्तराञ्चल</h3>
                        <p>श्री ज्योतिर्मठ, त्रोटकाचार्य गुफा, बदरिकाश्रम, हिमालय जोशीमठ, जिला-चमोली, गढवाल (उत्तराञ्चल)</p>
                        <span>संपर्क: </span><a href="tel:01389222185" className='call'>01389-222185</a><br />
                        <span>स्थान: </span><a href="https://goo.gl/maps/5rueWtR3qgCkuj9k9" target={"_blank"} className="location">ज्योतिर्मठ, उत्तराञ्चल</a>
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शारदापीठम्, गुजरात</h3>
                        <p>श्री शारदापीठम् द्वारका, जामनगर, गुजरात.</p>
                        <span>संपर्क: </span><a href="tel:02892235109" className='call'>02892-235109</a><br />
                        <span>स्थान: </span><a href="https://goo.gl/maps/RkPret4iH7rQ2rq6A" target={"_blank"} className="location">शारदापीठम् द्वारका</a>
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>परमहंसी गंगा आश्रम ज्योतेश्वर, मध्यप्रदेश</h3>
                        <p>परमहंसी गंगा आश्रम ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.).</p>
                        <span>संपर्क: </span><a href="tel:07794285210" className='call'>07794-285210</a><br />
                        <span>स्थान: </span><a href="https://goo.gl/maps/qR4GDZ2tWFK8MpdK9" target={"_blank"} className="location">परमहंसी गंगा आश्रम ज्योतेश्वर</a>
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>ज्योतिरीश्वर ऋषिकुल संस्कृत विद्यालय, मध्यप्रदेश</h3>
                        <p>ज्योतिरीश्वर ऋषिकुल संस्कृत विद्यालय परमहंसी गंगा आश्रम, ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.).</p>
                        <span>संपर्क: </span><a href="tel:07794285411" className='call'>07794-285411</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location">ज्योतिरीश्वर ऋषिकुल संस्कृत विद्यालय,(म.प्र.)</a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री शङ्कराचार्य बाल विद्या निकेतन, मध्यप्रदेश</h3>
                        <p>श्री शङ्कराचार्य बाल विद्या निकेतन परमहंसी गंगा आश्रम, ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>राजयोगाश्रम बाललीला धाम, मध्यप्रदेश</h3>
                        <p>राजयोगाश्रम बाललीला धाम, पो. बरहटा, जिला नरसिंहपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        <span>स्थान: </span><a href="https://goo.gl/maps/trh1ESfju1XmJtHJ7" target={"_blank"} className="location">राजयोगाश्रम बाललीला धाम, म.प्र.</a>
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>राजराजेश्वरी मन्दिर, मध्यप्रदेश</h3>
                        <p>राजराजेश्वरी मन्दिर पो. मोहनगढ़(मोहगडी), जिला पन्ना (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>बगलामुखी सिद्धपीठ शङ्कराचार्य आश्रम, मध्यप्रदेश</h3>
                        <p>बगलामुखी सिद्धपीठ शङ्कराचार्य आश्रम, सिविक सेण्टर, मढ़ाताल, जबलपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>गुरुरत्नेश्वर महादेव, मध्यप्रदेश</h3>
                        <p>गुरुरत्नेश्वर महादेव गुरुधाम, दिघौरी, सिवनी (म.प्र.).</p>
                        <span>संपर्क: </span><a href="tel:07692274027" className='call'>07692-274027</a><br />
                        <span>स्थान: </span><a href="https://goo.gl/maps/fh9VhDkSPJQk8nGS9" target={"_blank"} className="location">गुरुरत्नेश्वर महादेव, म.प्र.</a>
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>मातृधाम कातलबोडी, मध्यप्रदेश</h3>
                        <p>मातृधाम कातलबोडी, सिवनी (म.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:07692236421" className='call'>07692-236421</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्रीबालाभवानी मन्दिर, मध्यप्रदेश</h3>
                        <p>श्रीबालाभवानी मन्दिर गणेशगंज, सिवनी (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>गोविन्दनाथ वन, मध्यप्रदेश</h3>
                        <p>गोविन्दनाथ वन शङ्कराचार्य गुफा सांकलघाट, नरसिहपुर (मध्यप्रदेश).</p>
                        <span>संपर्क: </span><a href="tel:07794292158" className='call'>07794-292158</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>हिङ्गलाज भवानी मन्दिर, मध्यप्रदेश</h3>
                        <p>हिङ्गलाज भवानी मन्दिर पञ्चधारा तीर्थ, डोगरगाँव, नरसिंहपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, मध्यप्रदेश</h3>
                        <p>शङ्कराचार्य आश्रम नागनदेवरी, सिवनी (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>नर्मदेश्वर मन्दिर ( यज्ञशाला ), मध्यप्रदेश</h3>
                        <p>नर्मदेश्वर मन्दिर ( यज्ञशाला ) तिलवारा घाट, जबलपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>लक्ष्मीनारायण मन्दिर, मध्यप्रदेश</h3>
                        <p>लक्ष्मीनारायण मन्दिर रमनगरा, तिलवाराघाट, जबलपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, मध्यप्रदेश</h3>
                        <p>शङ्कराचार्य आश्रम पीथमपुर नैनोद बाईपास रोड, हिंकारगिरी के सामने, सरस्वती नगर, इन्दौर (म.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:9425071784" className='call'>9425071784</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम (झरनेश्वर महादेव), मध्यप्रदेश</h3>
                        <p>शङ्कराचार्य आश्रम (झरनेश्वर महादेव) नार्थ टी.टी.नगर, जवाहर चौक, भोपाल (म.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:07552777027" className='call'>0755-2777027</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>अध्यात्म विद्यापीठ, मध्यप्रदेश</h3>
                        <p>अध्यात्म विद्यापीठ पो. अमरकण्टक पर्वत, जिला शहडोल (म.प्र.) दूरभाष : 07629-269475</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, मध्यप्रदेश</h3>
                        <p>शङ्कराचार्य आश्रम पुराना बस स्टैण्ड, पो. अमरकण्टक, जिला शहडोल (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>ओङ्कारेश्वर महादेवालय, मध्यप्रदेश</h3>
                        <p>ओङ्कारेश्वर महादेवालय पो. उर्रहट, जिला रीवा (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, मध्यप्रदेश</h3>
                        <p>शङ्कराचार्य आश्रम ग्रा.पो. पवई, तह. पाटन, जिला जबलपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>उभयभारती महिला आश्रम, मध्यप्रदेश</h3>
                        <p>उभयभारती महिला आश्रम हीरापुर, पो. सांकल, जिला नरसिंहपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री जगन्नाथ मन्दिर, मध्यप्रदेश</h3>
                        <p>श्री जगन्नाथ मन्दिर पो. श्रीनगर, जिला नरसिंहपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री राधाकृष्ण मन्दिर, मध्यप्रदेश</h3>
                        <p>श्री राधाकृष्ण मन्दिर सरांजा, पो. देवेन्द्रनगर, जिला पन्ना (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>आनन्द सिन्धु आश्रम, मध्यप्रदेश</h3>
                        <p>आनन्द सिन्धु आश्रम गुलौआ चौक, गढ़ा, जबलपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री देवराम जानकी मन्दिर, मध्यप्रदेश</h3>
                        <p>श्री देवराम जानकी मन्दिर मु.पो. फूलसागर, जिला मण्डला (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री देवराम मन्दिर माथनपुर, मध्यप्रदेश</h3>
                        <p>श्री देवराम मन्दिर माथनपुर, पो. सुनवारा, जिला सिवनी (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री देवराम मन्दिर श्रीधाम, मध्यप्रदेश</h3>
                        <p>श्री देवराम मन्दिर श्रीधाम (गोटेगाँव) जिला नरसिंहपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री सहज संतोष आश्रम, मध्यप्रदेश</h3>
                        <p>श्री सहज संतोष आश्रम ग्राम- सिलादेही, नागपुर रोड़, सिवनी (म.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:07692216246" className='call'>07692-216246</a><br />
                        <span>स्थान: </span><a href="" target={"_blank"} className="location"></a>
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री शङ्कराचार्य विद्यालय, मध्यप्रदेश</h3>
                        <p>श्री शङ्कराचार्य विद्यालय झाँसी घाट, नरसिंहपुर (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>आनंदवर्धन श्री राम मंदिर, मध्यप्रदेश</h3>
                        <p>आनंदवर्धन श्री राम मंदिर कलबोडी (नागपुर रोड), सिवनी (म.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री राजराजेश्वरी मन्दिर, छत्तीसगढ़</h3>
                        <p>श्री राजराजेश्वरी मन्दिर सेक्टर-6, पो. भिलाई (छ.ग.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री शङ्कराचार्य आश्रम, छत्तीसगढ़</h3>
                        <p>श्री शङ्कराचार्य आश्रम धमतरी रोड, बोरियाकलॉ, रायपुर (छ.ग.)</p>
                        <span>संपर्क: </span><a href="tel:07712418484" className='call'>0771-2418484</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div><div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री शङ्कराचार्य आश्रम, छत्तीसगढ़</h3>
                        <p>श्री शङ्कराचार्य आश्रम लफन्दी, राजिस, रायपुर (छ.ग.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्रीद्वारकाधीश संस्कृत एकेडेमी, गुजरात</h3>
                        <p>श्रीद्वारकाधीश संस्कृत एकेडेमी द्वारका, जामनगर, गुजरात.</p>
                        <span>संपर्क: </span><a href="tel:02892234208" className='call'>02892-234208</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्रीशारदापीठ आर्ट्स-कामर्स एण्ड कॉलेज ऑफ एज्युकेशन, गुजरात</h3>
                        <p>श्रीशारदापीठ आर्ट्स-कामर्स एण्ड कॉलेज ऑफ एज्युकेशन, द्वारका, जामनगर, गुजरात. </p>
                        <span>संपर्क: </span><a href="tel:02892234068" className='call'>02892-234068</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री शङ्कराचार्य अभिनव सच्चिदानन्द तीर्थ संस्कृत महाविद्यालय, गुजरात</h3>
                        <p>श्री शङ्कराचार्य अभिनव सच्चिदानन्द तीर्थ संस्कृत महाविद्यालय द्वारका, जामनगर, गुजरात.</p>
                        <span>संपर्क: </span><a href="tel:02892235272" className='call'>02892-235272</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्रीशङ्कराचार्य अभिनव सच्चिदानन्द तीर्थ वेद विद्यालय, गुजरात</h3>
                        <p>श्रीशङ्कराचार्य अभिनव सच्चिदानन्द तीर्थ वेद विद्यालय द्वारका, जामनगर, गुजरात</p>
                        <span>संपर्क: </span><a href="tel:02892235272" className='call'>02892-235272</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री शङ्कराचार्य आश्रमशाला, गुजरात</h3>
                        <p>श्री शङ्कराचार्य आश्रमशाला वरवाला ओखा हाईवे रोड़, वरवाला, जामनगर, गुजरात</p>
                        <span>संपर्क: </span><a href="tel:02892234442" className='call'>02892-234442</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>पञ्चवटी नटेश्वर महादेव आश्रम, गुजरात</h3>
                        <p>पञ्चवटी नटेश्वर महादेव आश्रम वरवाला, द्वारका, जामनगर, गुजरात</p>
                        <span>संपर्क: </span><a href="tel:02892209261" className='call'>02892-209261</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>कैलाशधाम शङ्कराचार्य नगर, गुजरात</h3>
                        <p>कैलाशधाम शङ्कराचार्य नगर पो. रघवाणज, तह. मातर, जिला-खेडा (गुजरात)</p>
                        <span>संपर्क: </span><a href="tel:" className='call'>02694-289638</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>अद्वैत आश्रम, गुजरात</h3>
                        <p>अद्वैत आश्रम शाहपुर दरवाजा बाहर, गांधी ब्रिज के पास, अहमदाबाद, गुजरात</p>
                        <span>संपर्क: </span><a href="tel:07925600865" className='call'>079-25600865</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शारदा मठ, गुजरात</h3>
                        <p>शारदा मठ पो. डाकोर, जिला खेडा, गुजरात</p>
                        <span>संपर्क: </span><a href="tel:02699244467" className='call'>02699-244467</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, गुजरात</h3>
                        <p>शङ्कराचार्य आश्रम पो. धोलका, जिला अहमदाबाद, गुजरात</p>
                        <span>संपर्क: </span><a href="tel:02714220453" className='call'>02714-220453</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री शारदामठ, गुजरात</h3>
                        <p>श्री शारदामठ पो. प्रभास पाटन, सोमनाथ, त. तहसील, जिला जुनागढ़, गुजरात</p>
                        <span>संपर्क: </span><a href="tel:02876-231373" className='call'>02876-231373</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शरणेश्वर महादेव मन्दिर, गुजरात</h3>
                        <p>शरणेश्वर महादेव मन्दिर पो. खम्भात, जिला नडियाद, गुजरात</p>
                        <span>संपर्क: </span><a href="tel:02698221663" className='call'>02698-221663</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शारदापीठ संचालित चन्द्रमौलीश्वर धाम, गुजरात</h3>
                        <p>शारदापीठ संचालित चन्द्रमौलीश्वर धाम रतनपर, राजकोट, गुजरात</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शारदा मठ, गुजरात</h3>
                        <p>शारदा मठ मु.पो. भरूच, नर्मदा किनारे, गुजरात</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>चकलेश्वर मठ, गुजरात</h3>
                        <p>चकलेश्वर मठ बौठा, अहमदाबाद, गुजरात</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, उत्तराञ्चल</h3>
                        <p>शङ्कराचार्य आश्रम ज्ञानलोक कालोनी, सेक्टर-2, कनखल, हरिद्वार (उत्तराञ्चल)</p>
                        <span>संपर्क: </span><a href="tel:01334244024" className='call'>01334-244024</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य मठ, उत्तराञ्चल</h3>
                        <p>शङ्कराचार्य मठ टैक्सी स्टैण्ड, बद्रीनाथ धाम, जिला चमोली (उत्तराञ्चल)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शेषनेत्र आश्रम, उत्तराञ्चल</h3>
                        <p>शेषनेत्र आश्रम माना रोड़, बद्रीनाथ धाम, जिला चमोली (उत्तराञ्चल)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, उत्तराञ्चल</h3>
                        <p>शङ्कराचार्य आश्रम पुराना बाजार, चमोली (उत्तराञ्चल)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>विश्वकल्याण आश्रम, झारखण्ड</h3>
                        <p>विश्वकल्याण आश्रम पो.राजआनंदपुर, जिला-पश्चिम सिंहभूमि (झारखण्ड)</p>
                        <span>संपर्क: </span><a href="tel:06593287673" className='call'>06593-287673</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री विद्यामठ केदारघाट, उत्तरप्रदेश</h3>
                        <p>श्री विद्यामठ केदारघाट, वाराणसी-221001 (उ.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:05422450520" className='call'>0542-2450520</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>द्वारका शारदा मठ, उत्तरप्रदेश</h3>
                        <p>द्वारका शारदा मठ बी. 4/41 हनुमानघाट, वाराणसी-01 (उ.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:0542-2277137" className='call'>0542-2277137</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>दुर्गा सप्तशती मन्दिर, उत्तरप्रदेश</h3>
                        <p>दुर्गा सप्तशती मन्दिर सुखानन्द आश्रम, नगवा, वाराणसी (उ.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>आनन्द वनम्, उत्तरप्रदेश</h3>
                        <p>आनन्द वनम् बी. 30/94 लंका, वाराणसी (उ.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:05422366393" className='call'>0542-2366393</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य भवन, उत्तरप्रदेश</h3>
                        <p>शङ्कराचार्य भवन बी. 4/15 हनुमानघाट, वाराणसी (उ.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:05422275285" className='call'>0542-2275285</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>राम तारक मठ, उत्तरप्रदेश</h3>
                        <p>राम तारक मठ दुर्गाघाट, पञ्चगंगा घाट, वाराणसी (उ.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>मनकामेश्वर मन्दिर, उत्तरप्रदेश</h3>
                        <p>मनकामेश्वर मन्दिर सरस्वती घाट, किला रोड़, इलाहाबाद (उ.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:05322557321" className='call'>0532-2557321</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>मुक्ति कुटी, उत्तरप्रदेश</h3>
                        <p>मुक्ति कुटी श्री बिछुडन नाथ महादेव, पो. रामपुर, जिला गाजीपुर (उ.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, उत्तरप्रदेश</h3>
                        <p>शङ्कराचार्य आश्रम शाकम्भरी पीठ, पो. भूरादेव, जिला सहारनपुर (उ.प्र.)</p>
                        <span>संपर्क: </span><a href="tel:01322300399" className='call'>0132-2300399</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>राजराजेश्वरी मन्दिर, उत्तरप्रदेश</h3>
                        <p>राजराजेश्वरी मन्दिर सम्राट पैलेस, गढ़रोड, मेरठ (उ.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम, उत्तरप्रदेश</h3>
                        <p>शङ्कराचार्य आश्रम पक्का घाट, बागपत, मेरठ (उ.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>शङ्कराचार्य आश्रम (फुलवारी आश्रम), उत्तरप्रदेश</h3>
                        <p>शङ्कराचार्य आश्रम (फुलवारी आश्रम) पो. बहादुरपुर, जिला जालौन (उ.प्र.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री राजराजेश्वरी सेवा मठ, पश्चिम बंगाल</h3>
                        <p>श्री राजराजेश्वरी सेवा मठ बारह महादेव मन्दिर के सामने, पो. कोन्नगर, जिला हुगली (प.बं.)</p>
                        <span>संपर्क: </span><a href="tel:03326742001" className='call'>033-26742001</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री कामकामेश्वरी मंदिर, पश्चिम बंगाल</h3>
                        <p>श्री कामकामेश्वरी मंदिर श्रीरामपुर, जि. हुगली (प.बं.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री हिंगलाज पीठ, पश्चिम बंगाल</h3>
                        <p>श्री हिंगलाज पीठ कन्हाईपुर, नवग्राम, जि. हुगली (प.बं.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री शङ्कराचार्य आश्रम, पश्चिम बंगाल</h3>
                        <p>श्री शङ्कराचार्य आश्रम देवदेवश्वर महादेव मन्दिर, विष्णुपुर, जि. बाँकुडा (प.बं.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>ज. शं. स्वामी स्वरूपानन्द सरस्वती संस्कृत विद्यालय, पश्चिम बंगाल</h3>
                        <p>ज. शं. स्वामी स्वरूपानन्द सरस्वती संस्कृत विद्यालय, कोन्नगर, हुगली (प.बं.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री बाला भवानी मन्दिर श्याम, पश्चिम बंगाल</h3>
                        <p>श्री बाला भवानी मन्दिर श्याम लेक गॉर्डन्स, 202 जैसोर रोड, लेक टाउन, कोलकाता (प.बं.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>मानसी माँ आश्रम, कोलकाता</h3>
                        <p>मानसी माँ आश्रम पी-37, गरिया पार्क, कोलकाता</p>
                        <span>संपर्क: </span><a href="tel:03324304127" className='call'>033-24304127</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>हनुमान मन्दिर, महाराष्ट्र</h3>
                        <p>हनुमान मन्दिर जे.पी.नगर, गोरेगाँव (ई.), मुम्बई (महाराष्ट्र)</p>
                        <span>संपर्क: </span><a href="tel:02226864678" className='call'>022-26864678</a><br />
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>श्री कैलाश आश्रम, राजस्थान</h3>
                        <p>श्री कैलाश आश्रम श्रावंती नदी का किनारा, पो. साविधर, कांगमाला, तह. भीनमाल, जिला जालोर (राज.)</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-7.jpg" alt="" />
                    <div className='details'>
                        <h3>हिङ्गलाज भवानी मन्दिर शङ्कराचार्य आश्रम, दिल्ली</h3>
                        <p>हिङ्गलाज भवानी मन्दिर शङ्कराचार्य आश्रम, पडपडगंज, मधुविहार डिपो के पास, दिल्ली</p>
                        {/* <span>संपर्क: </span><a href="tel:" className='call'></a><br /> */}
                        {/* <span>स्थान: </span><a href="" target={"_blank"} className="location"></a> */}
                    </div>
                </div>
                
                
                {/* <div className='city'>
                    <img src="/assets/img/slide/slide-1.jpg" alt="" />
                    <div className='details'>
                        <h3>रायपुर,छत्तीसगढ़</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, fugiat quaerat? Eligendi necessitatibus voluptas quaerat repellat praesentium nesciunt harum alias neque ratione vero quasi vel aperiam at, saepe velit. Facere repudiandae quod expedita obcaecati, recusandae illum magnam iusto placeat iste rem! Maxime voluptatem quibusdam vel quo reprehenderit labore tempora nulla.</p>
                    </div>
                </div>
                <div className='city'>
                    <div className='details'>
                        <h3>अहमदाबाद,गुजरात</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, fugiat quaerat? Eligendi necessitatibus voluptas quaerat repellat praesentium nesciunt harum alias neque ratione vero quasi vel aperiam at, saepe velit. Facere repudiandae quod expedita obcaecati, recusandae illum magnam iusto placeat iste rem! Maxime voluptatem quibusdam vel quo reprehenderit labore tempora nulla.</p>
                    </div>
                    <img src="/assets/img/slide/slide-2.jpg" alt="" />
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-1.jpg" alt="" />
                    <div className='details'>
                        <h3>चमोली,उत्तराखंड</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, fugiat quaerat? Eligendi necessitatibus voluptas quaerat repellat praesentium nesciunt harum alias neque ratione vero quasi vel aperiam at, saepe velit. Facere repudiandae quod expedita obcaecati, recusandae illum magnam iusto placeat iste rem! Maxime voluptatem quibusdam vel quo reprehenderit labore tempora nulla.</p>
                    </div>
                </div>
                <div className='city'>
                    <div className='details'>
                        <h3>वाराणसी,उत्तर प्रदेश</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, fugiat quaerat? Eligendi necessitatibus voluptas quaerat repellat praesentium nesciunt harum alias neque ratione vero quasi vel aperiam at, saepe velit. Facere repudiandae quod expedita obcaecati, recusandae illum magnam iusto placeat iste rem! Maxime voluptatem quibusdam vel quo reprehenderit labore tempora nulla.</p>
                    </div>
                    <img src="/assets/img/slide/slide-2.jpg" alt="" />
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-1.jpg" alt="" />
                    <div className='details'>
                        <h3>सिंहभूमि,झारखंड</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, fugiat quaerat? Eligendi necessitatibus voluptas quaerat repellat praesentium nesciunt harum alias neque ratione vero quasi vel aperiam at, saepe velit. Facere repudiandae quod expedita obcaecati, recusandae illum magnam iusto placeat iste rem! Maxime voluptatem quibusdam vel quo reprehenderit labore tempora nulla.</p>
                    </div>
                </div>
                <div className='city'>
                    <div className='details'>
                        <h3>हुगली,पश्चिम बंगाल</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, fugiat quaerat? Eligendi necessitatibus voluptas quaerat repellat praesentium nesciunt harum alias neque ratione vero quasi vel aperiam at, saepe velit. Facere repudiandae quod expedita obcaecati, recusandae illum magnam iusto placeat iste rem! Maxime voluptatem quibusdam vel quo reprehenderit labore tempora nulla.</p>
                    </div>
                    <img src="/assets/img/slide/slide-2.jpg" alt="" />
                </div>
                <div className='city'>
                    <img src="/assets/img/slide/slide-1.jpg" alt="" />
                    <div className='details'>
                        <h3>मुंबई,महाराष्ट्र</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, fugiat quaerat? Eligendi necessitatibus voluptas quaerat repellat praesentium nesciunt harum alias neque ratione vero quasi vel aperiam at, saepe velit. Facere repudiandae quod expedita obcaecati, recusandae illum magnam iusto placeat iste rem! Maxime voluptatem quibusdam vel quo reprehenderit labore tempora nulla.</p>
                    </div>
                </div>
                <div className='city'>
                    <div className='details'>
                        <h3>जालोर,राजस्थान</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Delectus, fugiat quaerat? Eligendi necessitatibus voluptas quaerat repellat praesentium nesciunt harum alias neque ratione vero quasi vel aperiam at, saepe velit. Facere repudiandae quod expedita obcaecati, recusandae illum magnam iusto placeat iste rem! Maxime voluptatem quibusdam vel quo reprehenderit labore tempora nulla.</p>
                    </div>
                    <img src="/assets/img/slide/slide-2.jpg" alt="" />
                </div> */}
                {/* <div className="city">
                    <div>
                        1. श्री ज्योतिर्मठ, त्रोटकाचार्य गुफा, बदरिकाश्रम, हिमालय जोशीमठ, जिला-चमोली, गढवाल (उत्तराञ्चल), दूरभाष: 01389-222185
                        2. श्री शारदापीठम् द्वारका-361335, जामनगर, गुजरात, दूरभाष: 02892-235109
                        3. परमहंसी गंगा आश्रम ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.), दूरभाष: 07794-285210
                        4. ज्योतिरीश्वर ऋषिकुल संस्कृत विद्यालय परमहंसी गंगा आश्रम, ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.) दूरभाष: 07794-285411
                        5.श्री शङ्कराचार्य बाल विद्या निकेतन परमहंसी गंगा आश्रम, ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.)
                        6.राजयोगाश्रम बाललीला धाम, पो. बरहटा, जिला नरसिंहपुर (म.प्र.)
                        7  राजराजेश्वरी मन्दिर पो. मोहनगढ़(मोहगडी), जिला पन्ना (म.प्र.)
                        8  बगलामुखी सिद्धपीठ शङ्कराचार्य आश्रम, सिविक सेण्टर, ///////////////// madhata // जबलपुर (म.प्र.)
                        9  गुरुरत्नेश्वर महादेव गुरुधाम, दिघौरी, सिवनी (म.प्र.) दूरभाष: 07692-274027
                        10   मातृधाम  कातलबोडी, सिवनी (म.प्र.) दूरभाष:07692-236421
                        11  श्रीबालाभवानी मन्दिर गणेशगंज, सिवनी (म.प्र.)
                        12  गोविन्दनाथ वन शङ्कराचार्य गुफा सांकलघाट, नरसिहपुर (मध्यप्रदेश)  दूरभाष: 07794-292158
                        13 हिङ्गलाज भवानी मन्दिर  पञ्चधारा तीर्थ, डोगरगाँव, नरसिंहपुर (म.प्र.)
                        14  शङ्कराचार्य आश्रम नागनदेवरी, सिवनी (म.प्र.)
                        15  नर्मदेश्वर मन्दिर ( यज्ञशाला ) तिलवारा घाट, जबलपुर (म.प्र.)
                        16. लक्ष्मीनारायण मन्दिर रमनगरा, तिलवाराघाट, जबलपुर (म.प्र.)
                        17  शङ्कराचार्य आश्रम पीथमपुर नैनोद बाईपास रोड, हिंकारगिरी के सामने, सरस्वती नगर, इन्दौर (म.प्र.) मो. 9425071784

                    </div>
                </div> */}
                <div className="city">

                    {/* <div>
                        1. श्री ज्योतिर्मठ, त्रोटकाचार्य गुफा, बदरिकाश्रम, हिमालय जोशीमठ, जिला-चमोली, गढवाल (उत्तराञ्चल), दूरभाष: 01389-222185
                        2. श्री शारदापीठम् द्वारका-361335, जामनगर, गुजरात, दूरभाष: 02892-235109
                        3. परमहंसी गंगा आश्रम ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.), दूरभाष: 07794-285210
                        4. ज्योतिरीश्वर ऋषिकुल संस्कृत विद्यालय परमहंसी गंगा आश्रम, ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.) दूरभाष: 07794-285411
                        5. श्री शङ्कराचार्य बाल विद्या निकेतन परमहंसी गंगा आश्रम, ज्योतेश्वर, गोटेगाँव (श्रीधाम), नरसिंहपुर (म.प्र.)
                        6. राजयोगाश्रम बाललीला धाम, पो. बरहटा, जिला नरसिंहपुर (म.प्र.)
                        7  राजराजेश्वरी मन्दिर पो. मोहनगढ़(मोहगडी), जिला पन्ना (म.प्र.)
                        8  बगलामुखी सिद्धपीठ शङ्कराचार्य आश्रम, सिविक सेण्टर, मढ़ाताल, जबलपुर (म.प्र.)
                        9  गुरुरत्नेश्वर महादेव गुरुधाम, दिघौरी, सिवनी (म.प्र.) दूरभाष: 07692-274027
                        10 मातृधाम  कातलबोडी, सिवनी (म.प्र.) दूरभाष:07692-236421
                        11 श्रीबालाभवानी मन्दिर गणेशगंज, सिवनी (म.प्र.)
                        12 गोविन्दनाथ वन शङ्कराचार्य गुफा सांकलघाट, नरसिहपुर (मध्यप्रदेश)  दूरभाष: 07794-292158
                        13 हिङ्गलाज भवानी मन्दिर  पञ्चधारा तीर्थ, डोगरगाँव, नरसिंहपुर (म.प्र.)
                        14 शङ्कराचार्य आश्रम नागनदेवरी, सिवनी (म.प्र.)
                        15 नर्मदेश्वर मन्दिर ( यज्ञशाला ) तिलवारा घाट, जबलपुर (म.प्र.)
                        16 लक्ष्मीनारायण मन्दिर रमनगरा, तिलवाराघाट, जबलपुर (म.प्र.)
                        17 शङ्कराचार्य आश्रम पीथमपुर नैनोद बाईपास रोड, हिंकारगिरी के सामने, सरस्वती नगर, इन्दौर (म.प्र.) मो. 9425071784
                        18 शङ्कराचार्य आश्रम (झरनेश्वर महादेव) नार्थ टी.टी.नगर, जवाहर चौक, भोपाल (म.प्र.) दूरभाष: 0755-2777027
                        19 अध्यात्म विद्यापीठ  पो. अमरकण्टक पर्वत, जिला शहडोल (म.प्र.) दूरभाष : 07629-269475
                        20 शङ्कराचार्य आश्रम  पुराना बस स्टैण्ड, पो. अमरकण्टक, जिला शहडोल (म.प्र.)
                        21 ओङ्कारेश्वर महादेवालय पो. उर्रहट, जिला रीवा (म.प्र.)
                        22 शङ्कराचार्य आश्रम ग्रा.पो. पवई, तह. पाटन, जिला जबलपुर (म.प्र.)
                        23 उभयभारती महिला आश्रम हीरापुर, पो. सांकल, जिला नरसिंहपुर (म.प्र.)
                        24 श्री जगन्नाथ मन्दिर पो. श्रीनगर, जिला नरसिंहपुर (म.प्र.)
                        25 श्री राधाकृष्ण मन्दिर  सरांजा, पो. देवेन्द्रनगर, जिला पन्ना (म.प्र.)
                        26 आनन्द सिन्धु आश्रम गुलौआ चौक, गढ़ा, जबलपुर (म.प्र.)
                        27 श्री देवराम जानकी मन्दिर मु.पो. फूलसागर, जिला मण्डला (म.प्र.)
                        28 श्री देवराम मन्दिर माथनपुर, पो. सुनवारा, जिला सिवनी (म.प्र.)
                        29 श्री देवराम मन्दिर श्रीधाम (गोटेगाँव) जिला नरसिंहपुर (म.प्र.)
                        30 श्री सहज संतोष आश्रम ग्राम- सिलादेही, नागपुर रोड़, सिवनी (म.प्र.) दूरभाष : 07692-216246
                        31 श्री शङ्कराचार्य विद्यालय झाँसी घाट, नरसिंहपुर (म.प्र.)
                        32 आनंदवर्धन श्री राम मंदिर कलबोडी (नागपुर रोड), सिवनी (म.प्र.)
                        33 श्री राजराजेश्वरी मन्दिर सेक्टर-6, पो. भिलाई (छ.ग.)
                        34 श्री शङ्कराचार्य आश्रम धमतरी रोड, बोरियाकलॉ, रायपुर (छ.ग.) दूरभाष: 0771-2418484
                        35 श्री शङ्कराचार्य आश्रम लफन्दी, राजिस, रायपुर (छ.ग.)
                        36 श्रीद्वारकाधीश संस्कृत एकेडेमी द्वारका, जामनगर, गुजरात दूरभाष: 02892-234208
                        37 श्रीशारदापीठ आर्ट्स-कामर्स एण्ड कॉलेज ऑफ एज्युकेशन, द्वारका, जामनगर, गुजरात दूरभाष: 02892-234068
                        38 श्री शङ्कराचार्य अभिनव सच्चिदानन्द तीर्थ संस्कृत महाविद्यालय द्वारका, जामनगर, गुजरात दूरभाष: 02892-235272
                        39 श्रीशङ्कराचार्य अभिनव सच्चिदानन्द तीर्थ वेद विद्यालय द्वारका, जामनगर, गुजरात दूरभाष: 02892-235272
                        40 श्री शङ्कराचार्य आश्रमशाला वरवाला ओखा हाईवे रोड़, वरवाला, जामनगर, गुजरात दूरभाष: 02892-234442
                        41 पञ्चवटी नटेश्वर महादेव आश्रम वरवाला, द्वारका, जामनगर, गुजरात दूरभाष: 02892-209261
                        42 कैलाशधाम शङ्कराचार्य नगर पो. रघवाणज, तह. मातर, जिला-खेडा (गुजरात) दूरभाष: 02694-289638
                        43 अद्वैत आश्रम शाहपुर दरवाजा बाहर, गांधी ब्रिज के पास, अहमदाबाद, गुजरात दूरभाष: 079-25600865
                        44 शारदा मठ पो. डाकोर, जिला खेडा, गुजरात दूरभाष: 02699-244467
                        45 शङ्कराचार्य आश्रम पो. धोलका, जिला अहमदाबाद, गुजरात दूरभाष: 02714-220453
                        46 श्री शारदामठ पो. प्रभास पाटन, सोमनाथ, त. तहसील, जिला जुनागढ़, गुजरात दूरभाष: 02876-231373
                        47 शरणेश्वर महादेव मन्दिर पो. खम्भात, जिला नडियाद, गुजरात दूरभाष: 02698-221663
                        48 शारदापीठ संचालित चन्द्रमौलीश्वर धाम रतनपर, राजकोट, गुजरात
                        49 शारदा मठ मु.पो. भरूच, नर्मदा किनारे,गुजरात
                        50 चकलेश्वर मठ बौठा, अहमदाबाद, गुजरात
                        51 शङ्कराचार्य आश्रम ज्ञानलोक कालोनी, सेक्टर-2, कनखल, हरिद्वार (उत्तराञ्चल) दूरभाष: 01334-244024
                        52 शङ्कराचार्य मठ टैक्सी स्टैण्ड, बद्रीनाथ धाम, जिला चमोली (उत्तराञ्चल)
                        53 शेषनेत्र आश्रम माना रोड़, बद्रीनाथ धाम, जिला चमोली (उत्तराञ्चल)
                        54 शङ्कराचार्य आश्रम पुराना बाजार, चमोली (उत्तराञ्चल)
                        55 विश्वकल्याण आश्रम पो.राजआनंदपुर, जिला-पश्चिम सिंहभूमि- 833104 (झारखण्ड) दूरभाष: 06593-287673
                        56 श्री विद्यामठ केदारघाट, वाराणसी-221001 (उ.प्र.) दूरभाष: 0542-2450520
                        57 द्वारका शारदा मठ बी. 4/41 हनुमानघाट, वाराणसी-01 (उ.प्र.) दूरभाष: 0542-2277137
                        58 दुर्गा सप्तशती मन्दिर सुखानन्द आश्रम, नगवा, वाराणसी (उ.प्र.)
                        59 आनन्द वनम्  बी. 30/94 लंका, वाराणसी (उ.प्र.) दूरभाष: 0542-2366393
                        60 शङ्कराचार्य भवन बी. 4/15 हनुमानघाट, वाराणसी (उ.प्र.) दूरभाष: 0542-2275285
                        61 राम तारक मठ दुर्गाघाट, पञ्चगंगा घाट, वाराणसी (उ.प्र.)
                        62 मनकामेश्वर मन्दिर  सरस्वती घाट, किला रोड़, इलाहाबाद (उ.प्र.) दूरभाष: 0532-2557321
                        63 मुक्ति कुटी श्री बिछुडन नाथ महादेव, पो. रामपुर, जिला गाजीपुर (उ.प्र.)
                        64 शङ्कराचार्य आश्रम शाकम्भरी पीठ, पो. भूरादेव, जिला सहारनपुर (उ.प्र.) दूरभाष: 0132-2300399
                        65 राजराजेश्वरी मन्दिर सम्राट पैलेस, गढ़रोड, मेरठ (उ.प्र.)
                        66 शङ्कराचार्य आश्रम पक्का घाट, बागपत, मेरठ (उ.प्र.)
                        67 शङ्कराचार्य आश्रम (फुलवारी आश्रम) पो. बहादुरपुर, जिला जालौन (उ.प्र.)
                        68 श्री राजराजेश्वरी सेवा मठ बारह महादेव मन्दिर के सामने, पो. कोन्नगर, जिला हुगली (प.बं.) दूरभाष: 033-26742001
                        69 श्री कामकामेश्वरी मंदिर श्रीरामपुर, जि. हुगली (प.बं.)
                        70 श्री हिंगलाज पीठ कन्हाईपुर, नवग्राम, जि. हुगली (प.बं.)
                        71 श्री शङ्कराचार्य आश्रम देवदेवश्वर महादेव मन्दिर, विष्णुपुर, जि. बाँकुडा (प.बं.)
                        72 ज. शं. स्वामी स्वरूपानन्द सरस्वती संस्कृत विद्यालय, कोन्नगर, हुगली (प.बं.)
                        73 श्री बाला भवानी मन्दिर श्याम लेक गॉर्डन्स, 202 जैसोर रोड, लेक टाउन, कोलकाता (प.बं.)
                        74 मानसी माँ आश्रम पी-37, गरिया पार्क, कोलकाता-84 फोन : 033-24304127
                        75 हनुमान मन्दिर जे.पी.नगर, गोरेगाँव (ई.), मुम्बई (महाराष्ट्र) दूरभाष : 022-26864678
                        76 श्री कैलाश आश्रम श्रावंती नदी का किनारा, पो. साविधर, कांगमाला, तह. भीनमाल, जिला जालोर (राज.)
                        77 हिङ्गलाज भवानी मन्दिर शङ्कराचार्य आश्रम, पडपडगंज, मधुविहार डिपो के पास, दिल्ली

                    </div> */}
                </div>

                {/* <div className="city">
                    <iframe src="http://www.facebook.com/plugins/likebox.php?href=http%3A%2F%2Fwww.facebook.com%2FLEEWAY2372012&width=600&colorscheme=light&show_faces=true&border_color&stream=true&header=true&height=435" style={{ border: "1px solid black", overflow: "hidden", width: "800px", height: "300px", background: "black", float: "left" }} frameborder="0" allowTransparency="true"></iframe>
                </div> */}
            </div>
        </>
    );
}

export default GlobalNetwork;