import React from 'react';
// import Footer from './Footer';
import { Helmet } from "react-helmet";

export default function List_of_acharya() {

  return (
 <>
    <section id="breadcrumbs" class="breadcrumbs1">
      <section id="contact" class="contact1">
      <div>
        <Helmet>
  
            <title>List of Acharya | Acharya Parampara of Shri Dwarka Shardapeeth  </title>
            {/* <meta
                name="description"
                content="Get lyrics of every music for free"
            /> */}
        </Helmet>
        </div>

        <div>
          
          {/* <div class="row faq-item d-flex align-items-stretch"> */}
          {/* <div style="overflow-x:auto;"></div> */}
          <div class="col-lg-12">
            {/* <div class="info1"> */}
            
            <div class="text-center">
            <img style={{width:"100%",paddingBottom:"10px"}} src="assets/img/slide/aachary-parmpara.jpeg" alt="List of acharya" />
              <h1>Acharya Parampara of Shri Dwarka Shardapeeth</h1>
            </div>
          </div>
         
           {/* <div style="overflow-x:auto;"> */}
           {/* <div style="overflow:auto;"> */}
           
          <table id="customers">
          <tr>
              <th style={{textAlign:"center"}}>क्रम</th>
              <th style={{textAlign:"center"}}>आचार्यश्री</th>
              <th style={{textAlign:"center"}}>सन</th>
              <th style={{textAlign:"center"}}> तिथि </th>
            </tr>
            <tr>
              <td>१</td>
              <td>श्रीमद्ब्रह्मस्वरूपाचार्याः ..... (श्रीसुरेश्वराचार्याः)</td>
              <td>२६५१</td>
              <td>चैत्रवद्याष्टमीपर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>२</td>
              <td>चित्सुखाचार्याः</td>
              <td>२७१५</td>
              <td>पौष शुक्ल तृतीया पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>३</td>
              <td>श्रीसर्वज्ञानाचार्याः</td>
              <td>२७७४ </td>
              <td>श्रावण शुक्लैकादशी पर्यन्तम्। </td>
            </tr>
            <tr>
              <td>४</td>
              <td>श्रीब्रह्मानन्दतीर्थाः</td>
              <td>२८२३</td>
              <td>श्रावणशुक्लप्रतिपत्पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>५</td>
              <td>श्रीस्वरूपाभिज्ञानाचार्याः</td>
              <td>२८९०</td>
              <td>ज्येष्ठवद्यामावास्या पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>६</td>
              <td>श्रीमङ्गलमूर्त्याचार्याः </td>
              <td>२९४२</td>
              <td>पौष - शुक्लचतुर्दशी पर्यंन्तम् । </td>
            </tr>
            <tr>
              <td>७</td>
              <td>श्रीभास्कराचार्याः </td>
              <td>२९६५</td>
              <td>पौष-शुक्लद्वादशी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>८</td>
              <td>श्रीप्रज्ञानाचार्याः</td>
              <td> ३००८ </td>
              <td>आषाढ़ शुक्लसप्तमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>९</td>
              <td>श्रीब्रह्मज्योत्स्नाचार्याः </td>
              <td>३०४०</td>
              <td>चैत्र वद्यचतुर्थी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>१०</td>
              <td>श्री आनन्दाविर्भावाचार्याः </td>
              <td>वि.श. ९</td>
              <td>फाल्गुन शुक्लनवमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>११</td>
              <td>श्रीकलानिधितीर्थाः</td>
              <td>८२ </td>
              <td>पौष-शुक्लषष्ठी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>१२</td>
              <td>श्रीचिद्विविलासाचार्याः</td>
              <td>११९</td>
              <td>मार्गशीर्ष शुक्लत्रयोदशी पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>१३</td>
              <td>श्रीविभूत्यानन्दाचार्याः </td>
              <td> १५४ </td>
              <td>श्रावण वद्यैकादशी पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>१४</td>
              <td>श्रीस्फूर्तिनिलयपादाः </td>
              <td>२०३ </td>
              <td>आषाढ़ शुक्लषष्ठी पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>१५</td>
              <td>श्रीवरतन्तुपादाः </td>
              <td>२५९</td>
              <td>आषाढ़ वद्यतृतीया पर्यन्तम् ।   </td>
            </tr>
            <tr>
              <td>१६</td>
              <td>श्रीयोगारूढाचार्याः  </td>
              <td>३६० </td>
              <td>मार्गशीर्ष वद्यैकादशी पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>१७</td>
              <td>श्रीविजयडिण्डिमाचार्याः </td>
              <td>३९४</td>
              <td>पौष वद्याष्टमी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>१८</td>
              <td>श्रीविद्यातीर्थाः </td>
              <td> ४३७ </td>
              <td>चैत्र शुक्लप्रतिपत्पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>१९</td>
              <td>श्रीचिच्छक्तिदेशिकाः  </td>
              <td>४३८</td>
              <td>आषाढ़ शुक्लद्वादशी पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>२०</td>
              <td>श्रीविज्ञानेश्वरतीर्थाः </td>
              <td>५११</td>
              <td>आश्विन शुक्लपौर्णिमा पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>२१</td>
              <td>श्रीऋतम्भराचार्याः</td>
              <td>५७२</td>
              <td>माघ-शुक्लदशमी पर्यन्तम् । ।</td>
            </tr>
            <tr>
              <td>२२</td>
              <td>श्री अमरेश्वरगुरवः </td>
              <td>६०८</td>
              <td>भाद्रवद्यषष्ठीपर्यन्तम् ।   </td>
            </tr>
            <tr>
              <td>२३</td>
              <td>श्रीसर्वतोमुखतीर्थाः </td>
              <td>६६९  </td>
              <td>पौष - शुक्लचतुर्थी पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>२४</td>
              <td>श्री आनन्ददेशिका: </td>
              <td>७२१</td>
              <td> वैशाख वद्यपञ्चमी पर्यन्तम्।  </td>
            </tr>
            <tr>
              <td>२५</td>
              <td>श्रीसमाधिरसिकाः </td>
              <td>७९९</td>
              <td> फाल्गुन-शुक्लद्वादशी पर्यन्तम् ।   </td>
            </tr>
            <tr>
              <td>२६</td>
              <td>श्रीनारायणाश्रमाः   </td>
              <td>८३६</td>
              <td>चैत्र-शुक्लचतुर्दशी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>२७</td>
              <td>श्रीवैकुण्ठाश्रमाः  </td>
              <td>८८५  </td>
              <td>आषाढ वद्य पष्टी पर्यन्तम्। </td>
            </tr>
            <tr>
              <td>२८</td>
              <td>श्रीविक्रमाश्रमाः </td>
              <td> ९११ </td>
              <td>आषाढ शुक्ल तृतीया पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>२९</td>
              <td>श्रीनृसिंहाश्रमाः   </td>
              <td>९६०</td>
              <td>ज्येष्ठ वद्य चतुर्दशी पर्वतम्। </td>
            </tr>
            <tr>
              <td>३०</td>
              <td>श्री त्र्यम्बकाश्रमाः  </td>
              <td> ९६५ </td>
              <td>वैशाख वद्यामावास्या पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>३१</td>
              <td>श्रीविष्ण्वाश्रमाः   </td>
              <td>१००१</td>
              <td>  ज्येष्ठ-शुक्लप्रतिपत्पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>३२</td>
              <td>श्रीकेशवाश्रमाः </td>
              <td>१०६०</td>
              <td>  माघ वद्यपञ्चमी पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>३३</td>
              <td>श्रीचिदम्बराश्रमाः </td>
              <td>१०८३</td>
              <td>  मार्गशीर्ष वद्यनवमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>३४</td>
              <td>श्रीपद्मनाभाश्रमाः  </td>
              <td>११०९</td>
              <td> ज्येष्ठ शुक्लपौर्णिमा पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>३५</td>
              <td>श्रीमहादेवाश्रमाः  </td>
              <td>१९८४</td>
              <td>  श्रावण वद्यनवमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>३६</td>
              <td> श्रीसच्चिदानन्दाश्रमाः  </td>
              <td> १२०७  </td>
              <td> आश्विन वद्यपञ्चमी पर्यन्तम् |</td>
            </tr>
            <tr>
              <td>३७</td>
              <td>श्रीविद्याशङ्कराश्रमाः  </td>
              <td>१२६५</td>
              <td>आश्विन वद्यचतुर्थी पर्यनतम् | </td>
            </tr>
            <tr>
              <td>३८</td>
              <td>श्रीअभिनवसच्चिदानंदाश्रमाः  </td>
              <td> १२९३ </td>
              <td>वैशाख शुक्लषष्ठी पर्यन्तम् </td>
            </tr>
            <tr>
              <td>३९</td>
              <td> श्रीशशिशेखराश्रमाः  </td>
              <td>१३२६  </td>
              <td> वैशाख शुक्लप्रतिपत्पर्यन्तम् ।  </td>
            </tr>
            <tr>
              <td>४०</td>
              <td>श्रीवासुदेवाश्रमाः.  </td>
              <td>१३६२ </td>
              <td> फाल्गुन वद्यदशमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>४१</td>
              <td>श्रीपुरुषोत्तमाश्रमाः </td>
              <td>१३९४</td>
              <td> माघवद्यपञ्चमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>४२</td>
              <td>श्रीजनार्दनाश्रमाः  </td>
              <td>१४०८</td>
              <td> भाद्रपद शुक्लपक्षपौर्णिमा पर्यन्तम्।</td>
            </tr>
            <tr>
              <td>४३</td>
              <td>श्रीहरिहराश्रमाः </td>
              <td>१४११</td>
              <td> श्रावण शुद्धैकादशी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>४४</td>
              <td>श्रीभवाश्रमाः  </td>
              <td>१४२१</td>
              <td>आषाढ़ शुक्लनवमी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>४५</td>
              <td>श्रीब्रह्माश्रमाः  </td>
              <td>१४३६</td>
              <td>आषाढ़ शुक्लनवमी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>४६</td>
              <td>श्रीवामनाश्रमाः  </td>
              <td>१४५३</td>
              <td> चैत्र वद्यद्वादशी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>४७</td>
              <td>श्रीसर्वज्ञाश्रमाः </td>
              <td>१४८९</td>
              <td>चैत्र-वधाष्टमी पर्यन्तम्।</td>
            </tr>
            <tr>
              <td>४८</td>
              <td>श्रीप्रद्युम्नाश्रमाः </td>
              <td>१४९५</td>
              <td>चैत्र-शुक्लसप्तमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>४९</td>
              <td> श्रीगोविन्दाश्रमाः</td>
              <td> १५२३</td>
              <td>ज्येष्ठ वद्य चतुर्थी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>५०</td>
              <td>श्रीचिदाश्रमाः </td>
              <td>१५७६</td>
              <td> फाल्गुन शुक्लद्वितीया पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>५१</td>
              <td>श्रीविश्वेश्वराश्रमाः </td>
              <td>१६०८</td>
              <td> माघ शुक्लद्वितीया पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>५२</td>
              <td>श्रीदामोदराश्रमाः </td>
              <td>१६१५</td>
              <td> चैत्र वद्यपञ्चमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>५३</td>
              <td>श्रीमहादेवाश्रमाः </td>
              <td>१६१६</td>
              <td> चैत्र-शुक्लसप्तमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>५४</td>
              <td>श्रीअनिरुद्धाश्रमाः </td>
              <td>१६२५ </td>
              <td>माघ वद्यचतुर्थी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>५५</td>
              <td>श्रीअच्युताश्रमाः</td>
              <td> १६२९</td>
              <td>श्रावण वद्यषष्ठी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>५६</td>
              <td>श्रीमाधवाश्रमाः  </td>
              <td>१६६५</td>
              <td>माघ- - वद्यचतुर्थी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>५७</td>
              <td>श्री अनन्ताश्रमाः </td>
              <td>१७१६</td>
              <td>चैत्र शुक्ल द्वादशी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>५८</td>
              <td>श्रीविश्वरूपाश्रमाः  </td>
              <td>१७२१ </td>
              <td>श्रावण वद्यद्वितीया पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>५९</td>
              <td>श्रीचिद्धनाश्रमाः </td>
              <td>१७२६</td>
              <td>माघ-शुक्लषष्ठी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>६०</td>
              <td>श्रीनृसिंहा श्रमाः  </td>
              <td>१७३५ </td>
              <td>वैशाख-शुक्लचतुर्थी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>६१</td>
              <td>श्रीमनोहराश्रमाः  </td>
              <td>१७६१</td>
              <td>भाद्रपद शुक्लनवमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>६२</td>
              <td>श्रीप्रकाशानन्दसरस्वतीस्वामिनः </td>
              <td>१७९५ </td>
              <td>आश्विन वद्यपष्ठी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>६३</td>
              <td>श्रीविशुद्धाश्रमाः </td>
              <td>१७९९</td>
              <td>वैशाख वद्यामावास्या पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>६४</td>
              <td>श्रीवामनेन्द्राश्रमाः </td>
              <td>१८३१</td>
              <td>श्रावण शुक्लषष्ठी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>६५</td>
              <td>श्रीकेशवाश्रमाः </td>
              <td>१८३८</td>
              <td>कार्तिक वद्यनवमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>६६</td>
              <td> श्रीमघुसूदनाश्रमाः </td>
              <td>१८४८</td>
              <td>माघ शुक्लपञ्चमी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>६७</td>
              <td>श्रीहयग्रीवानन्दसरस्वतीस्वामिनः </td>
              <td>१८६२</td>
              <td> पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>६८</td>
              <td>श्रीप्रकाशाश्रमस्वामिनः  </td>
              <td>१८६३</td>
              <td> पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>६९</td>
              <td>श्रीहयग्रीवानन्दसरस्वतीस्वामिनः </td>
              <td>१८७४</td>
              <td> पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>७०</td>
              <td>श्रीधराश्रमाः स्वामिनः </td>
              <td>१९१४</td>
              <td> पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>७१</td>
              <td>श्रीदामोदराश्रमाः स्वामिनः </td>
              <td>१९२८</td>
              <td> पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>७२</td>
              <td>श्रीकेशवाश्रमाः स्वामिनः </td>
              <td>१९३६</td>
              <td>आश्विन वद्य सप्तमी पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>७३</td>
              <td>श्रीमद्राजराजेश्वरशंकराश्रमाः स्वामिनः </td>
              <td>१९५७</td>
              <td>आषाढशुक्लपंचमी पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>७४</td>
              <td>श्रीमन्माधवतीर्थस्वामिनः  </td>
              <td>१९७२</td>
              <td>भाद्रपदामावास्या पर्यन्तम् ।</td>
            </tr>
            <tr>
              <td>७५</td>
              <td>श्रीमच्छान्त्यानन्दसरस्वतीस्वामिनः </td>
              <td>१९८२</td>
              <td>पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>७६</td>
              <td>श्रीमच्चन्द्रशेखरा श्रमस्वामिनः </td>
              <td>२००१</td>
              <td>पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>७७</td>
              <td>श्रीमदभिनवसच्चिदानन्दतीर्थस्वामिनः </td>
              <td>२०३८</td>
              <td>पर्यन्तम् । </td>
            </tr>
            <tr>
              <td>७८</td>
              <td>श्रीमत्स्वरूपानन्दसरस्वतीस्वामिनः</td>
              <td>.....</td>
              <td>इदानीं विराजन्ते ।</td>
            </tr>
            <tr>
              <td>७९</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>८०</td>
              <td></td>
              <td></td>
              <td></td>
            </tr>


          
          </table>
         
          {/* </div> */}
        </div>
      </section>
    </section>
 </>


  )
}

{/* <div class="sidebar">

  <h3 class="sidebar-title">Search</h3>
  <div class="sidebar-item search-form">
    <form action="">
      <input type="text"/>
      <button type="submit"><i class="bi bi-search"></i></button>
    </form>
  </div>

  <h3 class="sidebar-title">Categories</h3>
  <div class="sidebar-item categories">
    <ul>
      <li><a href="#">General <span>(25)</span></a></li>
      <li><a href="#">Lifestyle <span>(12)</span></a></li>
      <li><a href="#">Travel <span>(5)</span></a></li>
      <li><a href="#">Design <span>(22)</span></a></li>
      <li><a href="#">Creative <span>(8)</span></a></li>
      <li><a href="#">Educaion <span>(14)</span></a></li>
    </ul>
  </div>

  <h3 class="sidebar-title">Recent Posts</h3>
  <div class="sidebar-item recent-posts">
    <div class="post-item clearfix">
      <img src="assets/img/blog/blog-recent-1.jpg" alt=""/>
      <h4><a href="blog-single.html">Nihil blanditiis at in nihil autem</a></h4>
      <time datetime="2020-01-01">Jan 1, 2020</time>
    </div>

    <div class="post-item clearfix">
      <img src="assets/img/blog/blog-recent-2.jpg" alt=""/>
      <h4><a href="blog-single.html">Quidem autem et impedit</a></h4>
      <time datetime="2020-01-01">Jan 1, 2020</time>
    </div>

    <div class="post-item clearfix">
      <img src="assets/img/blog/blog-recent-3.jpg" alt=""/>
      <h4><a href="blog-single.html">Id quia et et ut maxime similique occaecati ut</a></h4>
      <time datetime="2020-01-01">Jan 1, 2020</time>
    </div>

    <div class="post-item clearfix">
      <img src="assets/img/blog/blog-recent-4.jpg" alt=""/>
      <h4><a href="blog-single.html">Laborum corporis quo dara net para</a></h4>
      <time datetime="2020-01-01">Jan 1, 2020</time>
    </div>

    <div class="post-item clearfix">
      <img src="assets/img/blog/blog-recent-5.jpg" alt=""/>
      <h4><a href="blog-single.html">Et dolores corrupti quae illo quod dolor</a></h4>
      <time datetime="2020-01-01">Jan 1, 2020</time>
    </div>

  </div>

  <h3 class="sidebar-title">Tags</h3>
  <div class="sidebar-item tags">
    <ul>
      <li><a href="#">App</a></li>
      <li><a href="#">IT</a></li>
      <li><a href="#">Business</a></li>
      <li><a href="#">Mac</a></li>
      <li><a href="#">Design</a></li>
      <li><a href="#">Office</a></li>
      <li><a href="#">Creative</a></li>
      <li><a href="#">Studio</a></li>
      <li><a href="#">Smart</a></li>
      <li><a href="#">Tips</a></li>
      <li><a href="#">Marketing</a></li>
    </ul>
  </div>

</div> */}
// <section id="contact" class="contact"> 
// <section id="breadcrumbs1" class="breadcrumbs1"> </section>
// <div class="container">

{/* <div class="row faq-item d-flex align-items-stretch"> */ }
// <div class="col-lg-5">
//     <div class="pic"><img src="assets/img/slide/slide-10.jpg" class="img-fluid" alt="" /></div>
//  <i class="bx bx-help-circle"></i><h4>Tempus quam pellentesque nec nam aliquam sem et tortor consequat?</h4>
//     </div>
{/* <div class="col-lg-7">
                 <p>शारदा (कालिका) मठ गुजरात में द्वारकाधाम में स्थित है।
                शारदा मठ के अंतर्गत दीक्षा प्राप्त करने वाले सन्यासियों के नाम के
                बाद 'तीर्थ' और 'आश्रम' सम्प्रदाय नाम विशेषण लगाया जाता है जिससे उन्हें उक्त संप्रदाय का संन्यासी माना जाता है।
                इस मठ का महावाक्य है 'तत्त्वमसि' तथा इसके अंतर्गत 'सामवेद' को रखा गया है। शारदा मठ के प्रथम मठाधीश हस्तामलक (पृथ्वीधर) थे। हस्तामलक शंकराचार्य जी के प्रमुख चार शिष्यों में से एक थे।</p> 
             </div> */}
{/* </div> */ }
{/* <section id="breadcrumbs1" class="breadcrumbs1"></section>
            <div class="row faq-item d-flex align-items-stretch">
                 */}
{/* <div class="col-lg-7">
                 <p>शारदा (कालिका) मठ गुजरात में द्वारकाधाम में स्थित है।
                शारदा मठ के अंतर्गत दीक्षा प्राप्त करने वाले सन्यासियों के नाम के
                बाद 'तीर्थ' और 'आश्रम' सम्प्रदाय नाम विशेषण लगाया जाता है जिससे उन्हें उक्त संप्रदाय का संन्यासी माना जाता है।
                इस मठ का महावाक्य है 'तत्त्वमसि' तथा इसके अंतर्गत 'सामवेद' को रखा गया है। शारदा मठ के प्रथम मठाधीश हस्तामलक (पृथ्वीधर) थे। हस्तामलक शंकराचार्य जी के प्रमुख चार शिष्यों में से एक थे।</p> 
             </div> */}
{/* <div class="col-lg-5">
                    <div class="pic"><img src="assets/img/slide/slide-10.jpg" class="img-fluid" alt="" /></div>
                 </div> */}
{/* </div> */ }

        //     </div>
        // </section>