import {  Route, BrowserRouter, Routes } from "react-router-dom";
import Header from "./views/Header.jsx";
import Aboutus from "./views/Aboutus.jsx";
import Footer from "./views/Footer.jsx";
import Contact from "./views/Contact.jsx";
import Home from "./views/Home.jsx"; 
import Video from "./views/Video.jsx";
import Slider from "./views/Slider.jsx";
import About_Adi_Shankaracharya from "./views/About_Adi_Shankaracharya.jsx";
import List_of_acharya from "./views/List_of_acharya.jsx";
import Sharada_revelatuion from "./views/Sharada_revelatuion.jsx";
import Lord_Adya_Shankaracharya from "./views/Lord_Adya_Shankaracharya.jsx";
import Shankaracharya_opinion from "./views/Shankaracharya_opinion.jsx";
import History_of_Sri_Sharadapeetham_Dwarka from "./views/History_of_Sri_Sharadapeetham_Dwarka.jsx";
import Introduction_Swami_shreeswarupanand from "./views/Introduction_Swami_shreeswarupanand.jsx";
import Login from "./views/Login.jsx";
import Privacypolicy from "./views/Privacypolicy.jsx";
import GlobalNetwork from "./views/GlobalNetwork.jsx";
import Social from "./views/Social.jsx";
import Math from "./views/Math.jsx";
import Donet from "./views/Donet.jsx";
import Activity from "./views/Activity.jsx";
import Response from "./views/Response.jsx";
import Checkout from "./views/Checkout.jsx";
import Donet12 from "./views/Donet12.jsx";
import Abc1 from "./views/Abc1";







// const Layout = () => {
//   return (
//     <>
//       <Header></Header>
//       <Outlet />
//       <Footer> </Footer>
//     </>
//   );
// };
// const router = createBrowserRouter(
//   [
//     {
//       path: "/",
//       element: <Layout />,
//       children: [
//         {
//           path: "/Home",
//           element: <Home />,
//         },

//         {
//           path: "/Aboutus",
//           element: <Aboutus />,
//         },
//         {
//           path: "/Contact",
//           element: <Contact />,
//         },
//         {
//           path: "/Services",
//           element: <Services />
//         },
//         {
//           path: "/Tos",
//           element: <Tos />
//         },
//         {
//           path: "/Privacypolicy",
//           element: <Privacypolicy />
//         },
//         {
//           path: "/Slider",
//           element: <Slider />,
//         },
//         {
//           path: "/AboutAdiShankaracharya",
//           element: <AboutAdiShankaracharya />,
//         },
//         {
//           path: "/Listofacharya",
//           element: <Listofacharya />,
//         },
//         {
//           path: "/Sharadarevelatuion",
//           element: <Sharadarevelatuion />,
//         },
//         {
//           path: "/History",
//           element: <History />,
//         },
//         {
//           path: "/Shankaracharyaopinion",
//           element: <Shankaracharyaopinion />,
//         },
//         {
//           path: "/History1",
//           element: <History1 />,
//         },
//         {
//           path: "/Swamishreeswarupanand",
//           element: <Swamishreeswarupanand />,
//         }

//       ]
//     }
//   ]
// );
function App() {
  return (
    <>
      {/* <RouterProvider router={router}/> */}
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Header />}>
            <Route path="/" element={<Home />}/>
            {/* <Route path="/" element={<Abc1 />}/> */}
           
            <Route path="/Aboutus" element={<Aboutus />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Video" element={<Video />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Privacypolicy" element={<Privacypolicy />} />
            <Route path="/Slider" element={<Slider />} />
            <Route path="/About_Adi_Shankaracharya" element={<About_Adi_Shankaracharya />} />
            <Route path="/List_of_acharya" element={<List_of_acharya />} />
            <Route path="/Sharada_revelatuion" element={<Sharada_revelatuion />} />
            <Route path="/Lord_Adya_Shankaracharya" element={<Lord_Adya_Shankaracharya />} />
            <Route path="/Shankaracharya_opinion" element={<Shankaracharya_opinion />} />
            <Route path="/History_of_Sri_Sharadapeetham_Dwarka" element={<History_of_Sri_Sharadapeetham_Dwarka />} />
            <Route path="/Introduction_Swami_shreeswarupanand" element={<Introduction_Swami_shreeswarupanand />} />
            <Route path="/GlobalNetwork" element={<GlobalNetwork />} />
           <Route path="/Math"element={<Math/>}/>
           <Route path="/slider"element={<Slider/>}/>
           <Route path="/Donet"element={<Donet/>}/>
           <Route path="/Activity"element={<Activity/>}/>
           <Route path="/response"element={<Response/>}/>
           <Route path="/checkout"element={<Checkout/>}/>
           <Route path="/Donet"element={<Donet/>}/>
           {/* <Route path="/Slider"element={<Slider/>}/> */}
           
            {/* <Route path="/Social" element={<Social />} /> */}
          </Route>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}
export default App;