// import React,{useState} from 'react'
// import axios from 'axios'

// export default function Activity() {
// const [amount,setamount]=useState('');
// const [name, setName] = useState('');
// const [email, setEmail] = useState('');
// const [mobile, setMobile] = useState('');
// const [status,setStatus]=useState('');
// // const [handleClick, setHandleClick] = useState('');
// const [formErrors, setFormErrors] = useState({});
// const [bolt,setBolt]=useState('')
// const [num, setNum] = useState(0);


// function randomNumberInRange(min, max) {
//     // 👇️ get number between min (inclusive) and max (inclusive)
//     return Math.floor(Math.random() * (max - min + 1)) + min;
//   }

//   const handleClick = () => {
//     setNum(randomNumberInRange(10000,99999999));
//   };


// const validate = () => {
//     const errors = {};
//     if (!amount) {
//         errors.amount = "Please Enter amount";
//       }
      
//       if (!name) {
        
//         errors.name = "Please Enter name";
//                 }
//       if (!email) {
//         errors.email = "Please Enter email";
//       }
//       if (!mobile) {
//         errors.mobile = "Please Enter your mobile";
//       }
//       if (!status) {
//         errors.status = "Please Select Name ";
//       }
//       return errors;
//     };
//     const submit=async(e)=>{
//         e.preventDefault()
//         setFormErrors(validate())
//         handleClick()
//         try
//         {
//           const res = await axios.post("/donet/",{
//             amount,
//             email,
//             name,
//             mobile,
//             status
                                
//           });
//          alert(res.data)
//         //   navigate("/DisplayIndustries")
//         }
//         catch(err)
//         {
//             alert(err)
//         }
//     }

//   return (
//     <section id="breadcrumbs" class="breadcrumbs1">
//     <section id="contact" class="contact1">
//     <div class="container">
//         {/* <form action="#" class="p-5 form-group" id="payment_form" name="payment_form">
//                 <input type="hidden" id="udf5" name="udf5" value="BOLT_KIT_PHP7" />
//                 <input type="hidden" id="surl" name="surl" value="<?php echo getCallbackUrl(); ?>" />
//                 <div class="dv" style="display:none">
//                     <span class="text"><label>Merchant Key:</label></span>
//                     <span><input type="text" id="key" name="key" placeholder="Merchant Key" value="p8SKrfEh" /></span>
//                 </div>
    
//                 <div class="dv" style="display:none">
//                     <span class="text"><label>Merchant Salt:</label></span>
//                     <span><input type="text" id="salt" name="salt" placeholder="Merchant Salt" value="r57Wom0mBH" /></span>
//                 </div>
    
//                 <div class="dv" >
//                     <span class="text"><label>Transaction/Order ID:</label></span>
//                     <span><input type="text" id="txnid" name="txnid"  placeholder="Transaction ID" value={num}  readonly="" /></span>
//                 </div>
    
//                 <div class="dv">
//                     <span class="text"><label>Amount:</label></span>
//                     <span><input type="text" id="amount"  name="amount" placeholder="Amount" value="" /></span>    
//                 </div>
    
//                 <div class="dv">
//                     <span class="text"><label>Product Info:</label></span>
//                     <span>
//                 		<select name="pinfo" id="pinfo" class="select" required="">
//                 		    <option value="0">---Select Product Name---</option>
//                 			<option value='Annadana'>Annadana</option>
//                 			<option value='Education'>Education</option>
//                 			<option value='Medical Aid'>Medical Aid</option>
//                 			<option value='General Donation'>General Donation</option>
//                 		</select>
//                 	</span>
//                 </div>
    
//                 <div class="dv">
//                     <span class="text"><label>Name:</label></span>
//                     <span><input class="effect-9"  type="text" id="fname" name="fname" placeholder="First Name"  value={name}  onChange={(e) => setName(e.target.value)} /></span>
//                 </div>
    
//                 <div class="dv">
//                     <span class="text"><label>Email ID:</label></span>
//                     <span><input type="email" id="email" name="email"  placeholder="Email ID" value={email}  onChange={(e) => setEmail(e.target.value)} /></span>
//                 </div>
    
//                 <div class="dv">
//                     <span class="text"><label>Mobile/Cell Number:</label></span>
//                     <span><input type="number" id="mobile"  name="mobile" placeholder="Mobile/Cell Number" value={mobile}  onChange={(e) => setMobile(e.target.value)} /></span>
//                 </div>
    
//                 <div class="dv" style="display:none">
//                     <span class="text"><label>Hash:</label></span>
//                     <span><input type="text" id="hash" name="hash" placeholder="Hash" value="" /></span>
//                 </div>
    
//                 <div>
//                     <input class="btn center mt-3" type="submit" value=" &#x20B9; Pay" onclick="return validation()" />
//                 </div>
//                 <div class="text-center">
                
//                   <button type="submit" onClick={validate}> Submit </button>
//                   <button onClick={handleClick}>Generate random number</button>
//                   </div>
// 	        </form> */}
//               <form>
//                 <div class="dv"  style={{display:"none"}}>
//                     <span class="text12"><label>Merchant Key:</label></span>
//                     <span><input type="text" id="key" name="key" placeholder="Merchant Key" value="p8SKrfEh" /></span>
//                 </div>
//                 <div class="dv"  style={{display:"none"}} >
//                     <span class="text12"><label>Merchant Salt:</label></span>
//                     <span><input type="text" id="salt" name="salt" placeholder="Merchant Salt" value="r57Wom0mBH" /></span>
//                 </div>
    
//                 <div class="dv" >
//                     <span class="text12"><label>Transaction/Order ID:</label></span>
//                     <span><input type="text" id="txnid" name="txnid"  placeholder="Transaction ID" value={num} onLoad={handleClick} readonly=""  /></span>
//                 </div>
    
//                 <div class="dv">
//                     <span class="text12"><label>Amount:</label></span>
//                     <span><input type="text" id="name"  name="amount" placeholder="Amount"  defaultValue={amount}  onChange={(e) => setamount(e.target.value)} required />
//                     <p style={{ color: "red" }}>{formErrors.amount}</p>
//                     </span>    
                   
//                 </div>
    
//                 <div class="dv">
//                     <span class="text12"><label>Product Info:</label></span>
//                     <span>
//                 		<select name="pinfo" id="pinfo" class="select"   value={status}
//                       select onChange={(e) => setStatus(e.target.value)} >
//                 		    <option value="0">---Select Name---</option>
//                 			<option value='Annadana'>Annadana</option>
//                 			<option value='Education'>Education</option>
//                 			<option value='Medical Aid'>Medical Aid</option>
//                 			<option value='General Donation'>General Donation</option>
                			
//                 		</select>
//                         <p style={{ color: "red" }}>{formErrors.status}</p>
//                 	</span>
//                 </div>
    
//                 <div class="dv">
//                     <span class="text12"><label>Name:</label></span>
//                     <span><input class="effect-9"  type="text" id="fname" name="fname" placeholder="First Name"  value={name} onChange={(e) => setName(e.target.value)} />
//                     <p style={{ color: "red" }}>{formErrors.name}</p>
//                     </span>
//                 </div>
    
//                 <div class="dv">
//                     <span class="text12"><label>Email ID:</label></span>
//                     <span><input type="email" id="email" name="email"  placeholder="Email ID" value={email}  onChange={(e) => setEmail(e.target.value)}  />
//                     <p style={{ color: "red" }}>{formErrors.email}</p>
//                     </span>
//                 </div>
    
//                 <div class="dv">
//                     <span class="text12"><label>Mobile/Cell Number:</label></span>
//                     <span><input type="text" id="mobile"  name="mobile" placeholder="Mobile/Cell Number" value={mobile}  onChange={(e) => setMobile(e.target.value)} />
//                     <p style={{ color: "red" }}>{formErrors.mobile}</p>
//                     </span>
//                 </div>
    
//                 <div class="dv" style={{display:"none"}}>
//                     <span class="text12"><label>Hash:</label></span>
//                     <span><input type="text" id="hash" name="hash" placeholder="Hash" value="" /></span>
//                 </div>
//                 {/* <div>
//                     <input class="btn center mt-3" type="submit" value="Pay" onclick= {validate}/>
//                 </div> */}
//                 <div class="text-center">
                  
//                   <button type="submit" onClick={submit}> Submit </button>
//                   {/* <button onClick={handleClick}>Generate random number</button> */}
                  
//                 </div>
//                 </form>
//     {/* <div>Activity</div> */}
//     </div>
//     </section>
//     </section>
//   )
// }




import React,{useState,useEffect} from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { Helmet } from "react-helmet";
import {SlideshowLightbox} from 'lightbox.js-react'
import 'lightbox.js-react/dist/index.css'

export default function Activity() {
  const [activity,setActivity]= useState([]);
  const [video,setVideo]= useState([]);
    let i=1;

     useEffect(()=>{       
       
         fatchUsers();
         fatchUsers1();

     },[]);

     const fatchUsers= async()=>{
      try{
          const res = await axios.get("/activity");
          setActivity(res.data);                 
      }catch(err){
          console.log(err)
      }            
  }
  const fatchUsers1= async()=>{
    try{
        const res = await axios.get("/video");
        setVideo(res.data);                 
    }catch(err){
        console.log(err)
    }            
}
  return (
    
    <div>
      <section id="breadcrumbs" class="breadcrumbs1">

   
<section id="contact" class="contact1">
<div>
        <Helmet>
  
            <title>Our Activity | Shree Shardapith Dwarka </title>
            {/* <meta
                name="description"
                content="Get lyrics of every music for free"
            /> */}
        </Helmet>
        </div>
 <div>

 
 <div class="text-center">
 <img style={{width:"100%",paddingBottom:"10px"}} src="assets/img/slide/activity.jpeg" alt="Activity" />
  <h1> Our Activity  </h1> </div>

<h4>  🙏जय द्वारकाधीश,🙏
 आज भारत के ग्रह मंत्री श्री अमित शाह जी का द्वारका आगमान हुआ। उन्होंने भगवान द्वारकाधीश के दर्शन कर 
 अनंत श्रीविभूषित पश्चिमाम्नाय द्वारका शारदापीठाधीश्वर जगद्गुरु शंकराचार्य  स्वामी श्री सदानन्द सरस्वती जी महाराज श्री से आर्शीवाद प्राप्त किया।
</h4>

<div class='row'>
{/* <SlideshowLightbox > */}
        {/* <img class="lightbox" src="assets/img/slide/bhumipujan-shardapith-dwarka-pujan.jpg" alt="Bhumipujan Shardapith Dwarka Pujan" style={{hight:"50%",width:"30%",marginBottom:"20px"}}  />
        <img class="lightbox" src="assets/img/slide/bhumipujan-shardapith-dwarka-2.jpg" alt="Bhumipujan Shardapith Dwarka" style={{hight:"50%",width:"30%",marginBottom:"20px"}}  />
        <img class="lightbox" src="assets/img/slide/bhumipujan-shardapith-dwarka.jpg" alt="Bhumi Pujan Shree Shardapith Dwarka" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img class="lightbox" src="assets/img/slide/amit-1.jpeg" alt="Manniya Amitkumar" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img class="lightbox" src="assets/img/slide/shardapith-visit-dwarka-2.jpg" alt="Shardapith Visit Dwarka" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img class="lightbox" src="assets/img/slide/amit-3.jpeg" alt="Manniya Amitkumar" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img class="lightbox" src="assets/img/slide/odhav-ahm-visit.jpg" alt="Forth slide" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img class="lightbox" src="assets/img/slide/pujan-shardapith-dwaraka.jpg" alt="Five slide" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
        <img  class="lightbox" src="assets/img/slide/shardapith-visit-dwarka.jpg" alt="Five slide" style={{hight:"50%",width:"30%",marginBottom:"20px"}} /> */}
        {/* </SlideshowLightbox > */}
       {
        activity.map((activity,i)=> (
          <React.Fragment key={i}>
            {/* <div class="col-md-4">
            <div className='city1'> */}
            {/* <img src={"/upload/"+activity.image} alt='mukesh' style={{width:"150%"}}></img>  */}
            {/* <div className='details1'>
            <h1> {activity.name} </h1> 
            <p>{activity.description} </p>
            </div> */}
            {/* </div>
            </div> */}
            
           {/* <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 mb-4">  */}
           {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 mb-6">  */}
           {/* <h1> {activity.name} </h1> */} 
            {/* <div className="card" style={{ backgroundColor: "#FFFFFF", borderRadius: "18px" }}> */}
             {/* <img class="lightbox" src={"/upload/"+activity.image} alt='mukesh' width="200" height="250" ></img> <br></br> */}
            {/* <img  class="lightbox" src={"/upload/"+activity.image} alt="Five slide" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />  */}
            <img class="lightbox" src={"/upload/"+activity.image} alt="Forth slide" style={{hight:"50%",width:"30%",marginBottom:"20px"}} />
             {/* <div className='card-body'>
             <p class="mb-5" style={{textalign: "justify;",fontSize:16}}>{activity.description} </p>
             </div> */}
                {/* <div className="card-body">
                    <button className="delete" onClick={()=>handleDelete(activity.activity_id)}>Delete</button>
                 </div> */}
           {/* </div> */}
           {/* </div> */}
           
         </React.Fragment> 

       ))
      } 
     
       {/* {
              video.map((hiredeve,i)=> (
                <React.Fragment key={i}>
                  <div className='city1'>
                 
                  <ReactPlayer  url={"/upload/"+hiredeve.video} controls={true}   
                 
                  />
                 
                  </div>
                </React.Fragment>
              ))
        } */}
       
 
 </div>  
</div>
 
</section>
 </section>
    </div>
  )
}
