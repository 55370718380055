import React from "react";
// import Footer from "./Footer";
import { Helmet } from "react-helmet";

export default function About_Adi_Shankaracharya() {
  return (
   <> <section id="contact" class="contact">
      
   {/* <div class="container"> */}
 {/* <div class="sppb-addon-content"> */}
 <div>
        <Helmet>
  
            <title> About Adi Shankaracharya | आदि शंकराचार्य के बारे में </title>
            {/* <meta
                name="description"
                content="Get lyrics of every music for free"
            /> */}
        </Helmet>
        </div>

   <div className="mt-5 text-center">
   <div class="text-center">
                <h1> About Adi Shankaracharya</h1>
              </div>
   
   {/* <p style={{fontWeight:"400",textAlign:"center",textIndent:"100px",fontSize:"24px"}}><strong>आदि शंकराचार्य के बारे में :-</strong></p> */}
   <br></br>
   </div>    
   <div> 
   {/* <div class="img"><img src="assets/img/slide/slide-7.jpg" class="img-fluid" alt="" height="400" width="300" /></div> */}
          <div class="row"> 
          <div class='col-md-4 about' >
          <img src="assets/img/slide/slide-7.jpg" width="100%" class="img-fluid img-fluid1"  alt="आदि शंकराचार्य के बारे में"  />
          </div>
          <div class='col-md-8 aboutp'>
          <p class="text">
                 वैदिक सनातन धर्म की पुनर्स्थापना करने वाले महान दार्शनिक आदि शंकराचार्य
                  का जन्म भारत के केरल प्रांत में एर्णाकुलम जिले के ग्राम काल्टी में ईसा
                  पूर्व सन् 507 वैशाख शु0 5 के दिन हुआ था। उनके पिता का नाम
                  शिवगुरू तथा माता का नाम आर्याम्बा था। 8 वर्ष की आयु में
                  पूज्यपाद गोविन्द भगवत्पाद से कार्तिक शु0 11 को सन्यास ग्रहण किया।
                  उन्होंने प्रस्थानत्रयी आदि पर भाष्यों की रचना की। आचार्य शंकर का
                  आविर्भाव ऐसी विषम परिस्थिति में हुआ, जब सनातन हिन्दु धर्म
                  बलहीन, विध्वंश और विच्छिन्न हो गया था तथा विदेशी आक्रमण हो रहे थे।
                  बनाया। उन्होंने 16 वर्ष की आयु में महान कार्यों को सम्पादित
                  उन्होंने अपनी विद्वता एवं तप बल से बौद्ध विद्वानों को पराजित किया। श्री
                  मंडन मिश्र जैसे विद्वानों को भी उन्होंने शास्त्रार्थ में पराजित कर शिष्य
                  किया।
              </p>
              </div>
            </div>
    </div>
   {/* <p style={{fontWeight:"400",textAlign:"justify", textIndent:"200px"}}>
   <img src="assets/img/slide/slide-7.jpg" class="img-fluid" alt="" height="400" width="300" />
         वैदिक सनातन धर्म की पुनर्स्थापना करने वाले महान दार्शनिक आदि शंकराचार्य
     का जन्म भारत के केरल प्रांत में एर्णाकुलम जिले के ग्राम काल्टी में ईसा
     पूर्व सन् 507 वैशाख शु0 5 के दिन हुआ था। उनके पिता का नाम
     शिवगुरू तथा माता का नाम आर्याम्बा था। 8 वर्ष की आयु में
     पूज्यपाद गोविन्द भगवत्पाद से कार्तिक शु0 11 को सन्यास ग्रहण किया।
     उन्होंने प्रस्थानत्रयी आदि पर भाष्यों की रचना की। आचार्य शंकर का
     आविर्भाव ऐसी विषम परिस्थिति में हुआ, जब सनातन हिन्दु धर्म
     बलहीन, विध्वंश और विच्छिन्न हो गया था तथा विदेशी आक्रमण हो रहे थे।
     उन्होंने अपनी विद्वता एवं तप बल से बौद्ध विद्वानों को पराजित किया। श्री
     मंडन मिश्र जैसे विद्वानों को भी उन्होंने शास्त्रार्थ में पराजित कर शिष्य
     बनाया। उन्होंने 16 वर्ष की आयु में महान कार्यों को सम्पादित
     किया।
   </p> */}
   {/* </div> */}
   <div>

   <p style={{fontWeight:"400",textAlign:"justify",textIndent:"100px",fontSize:"19px"}}>
     राजा सुधन्वा को प्रभावित कर उन्हें शिष्य बनाया। आदि गुरू शंकराचार्य जी
     ने 32 वर्ष के संक्षिप्त जीवन काल में भारत वर्ष के सुदूर जनपदों
     का भ्रमण कर वैदिक सनातन हिन्दू धर्म का व्यापक प्रचार-प्रसार किया।
     सुप्तप्राय समाज को जागृत किया। अपने जीवन काल में उन्होंने अनेकों ग्रंथ
     लिखे तथा मंदिरों का जीर्णोद्वार कराया। धर्म की सत्ता निरंतर बनी रहे और
     आध्यात्मिक मूल्यों का उत्कर्ष होता रहे इस दृष्टि से महान भविष्य दृष्टा
     ने भारत वर्ष के 4 धार्मिक केन्द्रों में मठों की स्थापना की।
     मठाम्नाय महानुशासन में आचार्य श्री ने कहा है - ‘‘कृते विश्व गुरू ब्रम्हा
     त्रेतायां ऋषि सप्तमः। द्वापरे व्यास एवं स्यात्, कलावत्र
     भवाभ्यहम्।।‘‘ अर्थात् सतयुग में ब्रह्म, त्रेता में
     वशिष्ठ, द्वापर में वेद व्यास और कलयुग में भगवान शंकर ही विश्वगुरू
     हैं। उनके द्वारा स्थापित चारों पीठों के आचार्य शंकराचार्य की पद्वी से
     विभूषित होते हैं। उन्होंने पूर्व में पुरूषोत्तम क्षेत्र पुरी में ऋग्वेद
     से सम्बन्धित पूर्वाम्नाय गोवर्धनमठ की, दक्षिण में रामेश्वरम् में
     स्थित कर्नाटक के श्रृंगेरी में यजुर्वेद से सम्बद्ध दक्षिणाम्नाय
     की, गुजरात में द्वारिकापुरी (सामवेद) श्री शारदामठ एवं बद्रीनाथ
     क्षेत्र में उत्तर में ज्योतिर्मठ (अथर्ववेद) की स्थापना की। इसके अनुक्रम
     में उन्होंने पुरी में पद्यपाद महाभाग को, दक्षिण में हस्तामलकाचार्य
     को, पश्चिम में सुरेश्वर महाभाग को (मण्डल मिश्र) तथा उत्तर में
     तोटकायार्य महाभाग को शंकरचार्य के पद पर प्रतिष्ठित किया। उन्होंने अल्प
     समय में ही बौद्ध कापालिक, नास्तिकवाद, पाखॅंडवाद का
     खंडनकर, धर्म नियंत्रित पक्षपात विहीन शोषण विनिर्मुक्त वैदिक
     शासनतन्त्र की स्थापना की तथा भारत को अखंड भारत के रूप में प्रतिष्ठित
     किया। 32 वर्ष की आयु में लीला संवरण कर समाधि ली। आचार्य शंकर
     के समय भारत राजनैतिक, धार्मिक, सामाजिक दृष्टि से अनेक ईकाईयों
     में बंटा था। आवागमन भी कष्ट साध्य था- संचार साधन भी सीमित थे। अपने
     सन्यासी शिष्यों को वन, पर्वत, अरण्य
     तीर्थ, आश्रम, गिरि, पुरी, भारती, सागर एवं
     सरस्वती इन 10 भागों में विभक्त किया। ये सन्यासी अपने-अपने
     क्षेत्र में विचरण कर धर्म प्रचार के द्वारा समाज को संगठित करें, इस
     दृष्टि से क्षेत्र का विभाजन किया। गोवर्धन पीठ पुरी की स्थापना श्री
     शंकराचार्य जी द्वारा ईशा पूर्व सन् 486 के कार्तिक मास में की
     गई। इस पीठ के 145 वें शंकराचार्य स्वामी श्रीनिश्चनानन्द
     सरस्वती जी महाराज हैं।
   </p>
   <p style={{fontWeight:"400",textAlign:"justify",textIndent:"100px",fontSize:"20px"}}>
     आद्य शंकराचार्य ने विधान किया कि उनके द्वारा स्थापित 4 पीठों
     के पीठाधीश्वर उनके प्रतिभूति समझे जावेंगे। चार पीठों में शंकराचार्यों की
     परम्परा अनवरत चली आ रही है। हिन्दु धर्म के संरक्षण और उन्नयन में चारों
     पीठ की भूमिका महत्वपूर्ण है। आज भी शंकराचार्य जी की पीठ राष्ट्रीय एकता
     की वैजयन्ती  फहरा रही है। आद्य शंकराचार्य सनातन धर्म की विश्व
     व्यापी महानता के उदार प्रवक्ता थे। उन्होंने  वैदिक धर्म को
     अनंत युगों का स्थायित्व देकर उसे अत्यंत सुदृढ़ नींव प्रदान किया। हिन्दु
     धर्म का दर्शन चिरस्थायी है और सम्पूर्ण विश्व के लिये मंगलकारी है।
   </p>
   <p style={{fontWeight:"400",textAlign:"justify",textIndent:"100px",fontSize:"20px"}}>
     आद्य शंकराचार्य भगवान की आदर्श परम्परा के अनुसार पुरी पीठाधीश्वर
     जगद्गुरू शंकराचार्य स्वामी श्री निश्चलानन्द सरस्वती जी महाराज ने अन्यों
     के हित का ध्यान रखते हुए हिन्दुओं के अस्तित्व एवं आदर्श की
     रक्षा, देश की सुरक्षा तथा अखंडता के लिए धर्मसंघ पीठ
     परिषद, आदित्य वाहिनी एवं आनंद वाहिनी नामक संस्था का गठन किया
     है, जिसके माध्यम से पूरे भारत वर्ष में सार्वभौम सनातन धर्म का
     प्रचार-प्रसार निरन्तर जारी है।
   </p>
 </div>
 </section>
   </>
  );
}
