import React from 'react'

export default function response() {
  return (
    <div class="main" style={{marginleft:"30px;"}}>
	<div>
    	<img src="images/payumoney.png" />
    </div>
    <div>
    	<h3>NODE JS BOLT Kit Response</h3>
    </div>
	
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Merchant Key:</label></span>
    {/* <span><%= key %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Merchant Salt:</label></span>
    {/* <span><%= salt %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Transaction/Order ID:</label></span>
    {/* <span><%= txnid %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Amount:</label></span>
    {/* <span><%= amount %></span>     */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Product Info:</label></span>
    {/* <span><%= productinfo %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>First Name:</label></span>
    {/* <span><%= firstname %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Email ID:</label></span>
    {/* <span><%= email %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Mihpayid:</label></span>
    {/* <span><%= mihpayid %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Hash:</label></span>
    {/* <span><%= resphash %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Transaction Status:</label></span>
    {/* <span><%= status %></span> */}
    </div>
    
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <label>Message:</label></span>
    {/* <span><%= msg %></span> */}
    </div>
	<br />
	<br />
    <div class="dv" style={{marginbottom:"5px;"}}>
    <span class="text" style={{float:"left",width:"180px;"}}>
        <a href="http://localhost:3000" alt="New Order">New Order</a></span>    
    </div>
</div>
  )
}
