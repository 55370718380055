import React from 'react';
import { Helmet } from "react-helmet";
import { useState } from 'react'
export default function Donet() {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
    <>
      {/* <main id="main"> */}
      <section id="contact" class="contact">
        <div>
          <Helmet>

            <title>Donation: Shree Sardapith Dwarka,Gujarat | दान </title>
            {/* <meta
                name="description"
                content="Get lyrics of every music for free"
            /> */}
          </Helmet>
        </div>
        <section id="contact" class="contact1">

          <img style={{ width: "100%", padding: "10px" }} src="assets/img/slide/donet.jpeg" alt="दान" />

          <div class="row">
            {/* <img className="d-block w-100 carouselimg" src="assets/img/slide/donet.jpeg" alt="First slide" /> */}
            <div class="col-md-2" ></div>
            <div class="col-md-8" style={{ backgroundcolor: "lightgrey", width: "97%", border: "5px solid  #EEE9DA", padding: "10px", margin: "20px", textAlign: "center" }}>
              {/* <img className="d-block w-100 carouselimg" src="assets/img/slide/donet.jpeg" alt="First slide" />  */}
              <p style={{marginBottom : "2px"}}>Income Tax Exemption No. CIT Jam/TECHII(b)/80G/06-64/07-08/1070 Dated 07/09/2007 Validity Permanent</p>
              <p style={{marginTop : "2px"}}>Trust Reg. No. F/53 jamnagar || PAN No. AAHTS0902Q</p>
              <h1>Shri Shardaeeth Vidya Sabha, Dwarka </h1>
              <p style={{margin : "3px"}}>Shri Shardapeetham, Dwarka-361335, Dist. Jamnagar,Gujarat,India</p>
              <p>Tel.: 02892-235108,235109  |  Fax : 02892-234457</p>
            </div>
            <div class="col-md-2" ></div>
          </div>
          <div class="row   main-bank">
            <div class="col-md-4  custom-container">
              <p style={{ fontSize: "18px", textAlign: "center" }} >  <b>Shri Shardapeeth, Dwarka <br></br> A/c No. 56090007152, SBI Dwarka</b></p>
              <b>Activity </b> <br></br>
              <ol>
                <li> Atithi Nivas</li>
                <li> Natural Trouble</li>
                <li> Book Publication</li>
                <li> Poor Asistence Lord Chandramaulishwar's Puja etc.</li>
              </ol>
            </div>
            <div class="col-md-4 custom-container" >
              <p style={{ fontSize: "18px", textAlign: "center" }}><b>Shri Shardapeeth Vidyasabha, Dwarka <br></br> A/c No. 56090007196, SBI Dwarka</b></p>
              <b>Activity </b> <br></br>
              <ol>
                <li> Shri Shankaracharya Sanaskrit Mahavidyalya</li>
                <li> Shri Shankrachary Ved Vidyalya</li>
                <li> Shri Dwarkadhish Sanskrit Academy </li>
                <li> Shri Shardapeeth Art's Commerce & Collage of Education (Hostel Facility)</li>
                <li>Ashramshala Varvala (Hostel Facility)</li>
              </ol>

            </div>
            <div class="col-md-4 custom-container" >
              <p style={{ fontSize: "18px", textAlign: "center" }} > <b>Shri Jagadguru Shankaracharya Memorial Trust, Dwarka <br></br> A/c No. 56090006170, SBI Dwarka</b></p>
              <b>Activity </b> <br></br>
              <ol>
                <li> Ayurvedic Aushadhalya & Ayurvedic Camp (No Cost basis)</li>
              </ol>
            </div>

            <div class="col-md-4 custom-container" >
              <p style={{ fontSize: "18px", textAlign: "center" }} > <b>Shri Sharda Peeth Trust, Dwarda <br/>Goregaon East, Mumbai-400063<br/> A/c No. 17580100007151, Bank Of Baroda <br/> IFSC : 
BARB0GOREAS</b></p>
              {/* <b>Activity </b> <br></br>
              <ol>
                <li> Ayurvedic Aushadhalya & Ayurvedic Camp (No Cost basis)</li>
              </ol> */}
            </div>
            
          </div>
          {/* </div> */}


          <div class="row">
            <div class="col-md-12 custom-container" >
              {/* <div className='card'> */}
              <p style={{ fontSize: "18px", textAlign: "center" }} ><b>Shri Laxmi Bhandar, Dwarka  (Note:Without 80G)  <br></br> A/c No. 56090006476, SBI Dwarka</b></p>
              <p style={{ fontSize: "18px", textAlign: "center" }}>(1) Bhojnalya (2) Gaushala (3) No Cast Basis Student's Lunch & Dinner (4) Sixteen Temple's Puja, Bhog </p>
              {/* </div> */}
            </div>
          </div>
          {/* <div className="row">
            <div class="col-md-4" style={{ backgroundcolor: "lightgrey", border: "5px solid  #EEE9DA" }}>
              <img className="d-block w-100 carouselimg1" src="assets/img/slide/Shardapith-idyasabha.jpeg" alt="First slide" />
            </div>
            <div class="col-md-4" style={{ backgroundcolor: "lightgrey", border: "5px solid  #EEE9DA" }}>
              <img className="d-block w-100 carouselimg1" src="assets/img/slide/shardapith-math-Gpay-QR.jpeg" alt="First slide" />
            </div>
            <div class="col-md-4" style={{ backgroundcolor: "lightgrey", border: "5px solid  #EEE9DA" }}>
              <img className="d-block w-100 carouselimg1" src="assets/img/slide/shri-laxmiji-mandir.jpeg" alt="First slide" />
            </div>
          </div> */}

          <div className="gallery">
            <img className="d-block w-100 carouselimg1" src="assets/img/slide/Shardapith-idyasabha.jpeg" alt="First slide" />
            <img className="d-block w-100 carouselimg1" src="assets/img/slide/shardapith-math-Gpay-QR.jpeg" alt="First slide" />
            <img className="d-block w-100 carouselimg1" src="assets/img/slide/shri-laxmiji-mandir.jpeg" alt="First slide" />
          </div>


          {/* 
      <div class="col-md-12" style={{backgroundcolor:"lightgrey",border:"5px solid  #EEE9DA"}}>
      <p style={{fontSize:"18px",textAlign:"center"}} ><b>Kindly Help in Above Religious, Social, Health & Educational Activities by All Needs etc</b></p>
     <hr></hr>
      <p style={{fontSize:"18px",textAlign:"center"}} ><b>Shri Shardapeeth</b><br></br> Dwarka-361335, Jamnagar, Gujarat<br></br>Phone: 02892-235109</p>
      <p style={{fontSize:"18px",textAlign:"center"}} ><b>Note:</b><br></br> <b> Chequs, Draft & Cash </b> May be send for above purpose in favor of Organization. <br></br><b> For more Information Please Contact Office.</b></p>
      </div>
       */}
          <div class="row">
            <div class="col-md-12" style={{ backgroundcolor: "lightgrey", width: "97%", border: "5px solid  #EEE9DA", padding: "10px", margin: "20px" }}>
              {/* <div className='card'> */}
              <p style={{ fontSize: "18px", textAlign: "center" }} ><b>Kindly Help in Above Religious, Social, Health & Educational Activities by All Needs etc</b></p>
              <hr></hr>
              <p style={{ fontSize: "18px", textAlign: "center" }}><b>Shri Shardapeeth</b><br></br> Dwarka-361335, Jamnagar, Gujarat<br></br>Phone: 02892-235109<br></br><p>Email ID: <a href="mailto:shardapithmathdwarka@gmail.com">shardapithmathdwarka@gmail.com</a></p></p>
              <p style={{ fontSize: "18px", textAlign: "center" }} ><b>Note:</b><br></br> <b> Chequs, Draft & Cash </b> May be send for above purpose in favor of Organization. <br></br><b> For more Information Please Contact Office.</b></p>
              {/* </div> */}
            </div>
          </div>


        </section>
      </section>
      {/* </main> */}
    </>

  )
}
