import React from 'react';
import { Link } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

export default function Header() {
  return (
    <>
      <header id="header" className="fixed-top d-flex align-items-center">
        {/* <div class="container-fluid d-flex align-items-center">
        <h1 class="logo me-auto">
          <Link class="nav-link " to="./Home">
            <div class="pic"><img src="assets/img/slide/slide-6.png" class="img-fluid" alt="shardapith logo" /> शारदा पीठ द्वारका  </div>
          </Link>
        </h1>
        <nav id="navbar" class="navbar">
          <ul>
            <li>
              <li class="nav-link">
                <Link class="nav-link" to="/Home">
                  <span> मुख्य पृष्ठ</span>
                </Link>
              </li>
            </li>
            <li class="dropdown">
              <a href="#"><span>हमारे बारे में</span> <i class="bi bi-chevron-down"></i></a>
              <ul>
                <li class="nav-item">
                  <Link class="nav-link " to="/AboutAdiShankaracharya">
                    <span> AboutAdiShankaracharya</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link " to="/History1">
                    <span> History1</span>
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link " to="/Swamishreeswarupanand">
                    <span> Swamishreeswarupanand</span>
                  </Link>
                </li>
                <li>
                  <li class="nav-item">
                    <Link class="nav-link" to="/Listofacharya">
                      <span> Listofacharya</span>
                    </Link>
                  </li>
                </li>
              </ul>
            </li>
            <li class="nav-item">
              <Link class="nav-link" to="/Sharadarevelatuion" >
                Sharadarevelatuion
              </Link></li>
            <li><Link class="nav-link" to="/History"> इतिहास</Link></li>
            <li><Link class="nav-link" to="/Shankaracharyaopinion">शङ्कराचार्य जी का मत</Link></li>

            <li>
              <li class="nav-item">
                <Link class="nav-link" to="/Contact">
                  <span> संपर्क करें</span>
                </Link>
              </li>

            </li>

          </ul>

          <i class="bi bi-list mobile-nav-toggle"></i>
        </nav>
        &nbsp;&nbsp;&nbsp;
        <div class="header-social-links">
          <a href="#" class="twitter"><i class="bi bi-twitter"></i></a> &nbsp;
          <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>&nbsp;&nbsp;
          <a href="#" class="instagram"><i class="bi bi-instagram"></i></a> &nbsp;
          <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>&nbsp;
        </div>
      </div> */}
        <div className="container-fluid d-flex align-items-center">
          {/* <h1 class="logo ">
          <Link class="nav-link " to="./Home">
            <div class="pic"><img src="assets/img/slide/slide-6.png" class="img-fluid" alt="shardapith logo" /> शारदा पीठ द्वारका  </div>
          </Link>
        </h1> */}


          <nav id="navbar" className="navbar">
            {/* <h1 className="logo ">
                <a className="nav-link" href="./">
                  <div className="pic"><img src="assets/img/slide/slide-6.png" className="img-fluid" alt="shardapith logo" /> शारदा पीठ द्वारका  </div>
                </a>
              </h1> */}
            <div class="navbar-container container">
              <h1 className="logo ">
                <a className="nav-link" href="./">
                  <div className="pic"><img src="assets/img/slide/slide-6.png" className="img-fluid" alt="shardapith logo" /> शारदा पीठ द्वारका  </div>
                </a>
              </h1>
              <input class="checkbox" style={{ display: "hidden" }} type="checkbox" name="" id="cbox" />
              <div class="hamburger-lines">
                <span class="line line1"></span>
                <span class="line line2"></span>
                <span class="line line3"></span>
              </div>
              {/* <div class="menu-items">  */}
              <ul class="menu-items">

                {/* <h1 className="logo ">
                <a className="nav-link" href="./">
                  <div className="pic"><img src="assets/img/slide/slide-6.png" className="img-fluid" alt="shardapith logo" /> शारदा पीठ द्वारका  </div>
                </a>
              </h1> */}
                <a className="nav-link" href="/">
                  <span> मुख्य पृष्ठ</span>
                </a>


                <Link to="/Sharada_revelatuion" className="nav-link">शारदा प्रकाशनम्</Link>
                <Link to="/Shankaracharya_opinion" className="nav-link">शङ्कराचार्य जी का मत</Link>
                <Link to="/Contact" className="nav-link">संपर्क करें</Link>

                <div className="dropdown">
                  <span><Link to="#" className="nav-link">इतिहास <i className="bi bi-chevron-down"></i></Link> </span>
                  <ul className="menu-items">
                    <Link to="/About_Adi_Shankaracharya" className="nav-link">
                      <span> आदि शंकराचार्य </span>
                    </Link>
                    <Link to="/History_of_Sri_Sharadapeetham_Dwarka" className="nav-link">
                      <span> श्री शारदापीठम द्वारका </span>
                    </Link>
                    <Link to="/Lord_Adya_Shankaracharya" className="nav-link">
                      भगवान् आद्यशङ्कराचार्य
                    </Link>
                    <Link to="/Introduction_Swami_shreeswarupanand" className="nav-link">
                      <span> स्वामि श्रीस्वरूपानन्द</span>
                    </Link>
                    <Link to="/List_of_acharya" className="nav-link">
                      <span> आचार्य की सूची</span>
                    </Link>
                  </ul>
                </div>
                <div className="dropdown">
                  <span><Link to="#" className="nav-link">हमारे बारे में  <i className="bi bi-chevron-down"></i></Link></span>
                  <ul className="menu-items">
                    <Link to="/GlobalNetwork" className="nav-link">
                      <span>वैश्विक नेटवर्क</span>
                    </Link>
                    <Link to="/Activity" className="nav-link">
                      <span> गतिविधि</span>
                    </Link>
                    <Link to="/Video" className="nav-link">
                      <span> वीडियो </span>
                    </Link>
                  </ul>
                </div>


                {/* <a className="nav-link" href="/History"> इतिहास</a> */}

                {/* <a style={{border:"solid",textAlign:"center"}} href="/Donet" > ₹ डोनेट </a>  */}
                {/* <li className="nav-item">
                <a className="nav-link" href="/Sharadarevelatuion">
                  शारदा प्रकाशनम्
                </a></li>
              <li><a className="nav-link" href="/History"> इतिहास</a></li>
              <li><a className="nav-link" href="/Shankaracharyaopinion">शङ्कराचार्य जी का मत</a></li> */}
                {/* <li><a className="nav-link" href="/Social">Social</a></li> */}

                {/* <h1 className="logo ">
                <a className="nav-link " href="./">
                  <div className="pic"><img src="assets/img/slide/slide-6.png" className="img-fluid" alt="shardapith logo" /> शारदा पीठ द्वारका  </div>
                </a>
              </h1> */}


                {/* <li>
                <li className="nav-item">
                  <a className="nav-link" href="/Contact">
                    <span> संपर्क करें</span>
                  </a>
                </li>

              </li> */}
                {/* <li>
                <li className="nav-item">
                  <a className="nav-link" href="/Header1">
                    <span> Header1 </span>
                  </a>
                </li>

              </li> */}

              </ul>

            </div>
            {/* </div>   */}


            {/* </div> */}
            {/* <i className="bi  mobile-nav-toggle bi-x"></i> */}
            {/* <i class="bi bi-list mobile-nav-toggle"> 
            <div class="container nav-container">
            <input class="checkbox" type="checkbox" name="" id="" />
            <div class="hamburger-lines">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>  
            </div>
            <div class="menu-items"> */}
            {/* <li><a href="#">Home</a></li>
            <li><a href="#">about</a></li>
            <li><a href="#">blogs</a></li>
            <li><a href="#">portfolio</a></li>
            <li><a href="#">contact</a></li>
          */}
            {/* <li>
                <li className="nav-item">
                  <a className="nav-link" href="/Contact">
                    <span> संपर्क करें</span>
                  </a>
                </li>

              </li> */}
            {/* </div>
            </i> */}
            {/* <i className="bi bi-list mobile-nav-togge"></i> */}


          </nav>
          {/* <nav>
      <div class="navbar">
        <div class="container nav-container">
            <input class="checkbox" type="checkbox" name="" id="" />
            <div class="hamburger-lines">
              <span class="line line1"></span>
              <span class="line line2"></span>
              <span class="line line3"></span>
            </div>  
          
          <div class="menu-items">
            <li><a href="#">Home</a></li>
            <li><a href="#">about</a></li>
            <li><a href="#">blogs</a></li>
            <li><a href="#">portfolio</a></li>
            <li><a href="#">contact</a></li>
          </div>
        </div>
      </div>
    </nav> */}
          {/* &nbsp;&nbsp;&nbsp; */}


          <div className="header-social-links">
            <Link to="/Donet">
              <button style={{ backgroundColor: "yellow", marginLeft: "1px", marginRight: "70px", width: "85px", borderRadius: "5px 5px 5px 5px" }}>
                <b><span style={{ color: "black" }}> Donation </span></b>
              </button>
            </Link>

            {/* <a href="https://twitter.com/Dwarkamath" target={"_blank"} className="twitter"><i className="bi bi-twitter"></i></a> &nbsp;
            <a href="https://www.facebook.com/shrisharadapeetham/" target={"_blank"} className="facebook"><i className="bi bi-facebook"></i></a>&nbsp;&nbsp;
            <a href="https://www.youtube.com/channel/UCVHOQO030wrqfdokc15lMfA" target={"_blank"} className="youtube"><i className="bi bi-youtube"></i> </a> &nbsp;&nbsp; */}
            <i class="bi bi-phone"></i> 6352717211
            {/* <div class="phone">
                  <i class="bi bi-phone"></i>
                  <h4>Call:</h4>
                  <p> 02892-235109</p>
                </div> */}
          </div>
        </div>

        <div className="headersociallinks">
          <div style={{ width: "50px", textAlign: "center", height: "50px", backgroundColor: "rgb(29, 155, 240)", marginBottom: "2px" }} >
            <a style={{ fontSize: "34px" }} href="https://twitter.com/dandiswami?lang=en" target={"_blank"} className="twitter" ><i style={{ color: "white" }} className="bi bi-twitter"></i></a> &nbsp;<br />

          </div>
          <div style={{ width: "50px", height: "50px", backgroundColor: "#3b5998", marginBottom: "2px" }} >
            <a style={{ fontSize: "34px", alignItems: "center", marginLeft: "10px" }} href="https://www.facebook.com/shrisharadapeetham/" target={"_blank"} className="facebook"><i style={{ color: "white" }} className="bi bi-facebook"> </i></a>&nbsp;&nbsp;<br />

          </div>
          <div style={{ width: "50px", height: "50px", backgroundColor: "red", marginBottom: "2px" }} >
            <a style={{ fontSize: "34px", alignItems: "center", marginLeft: "10px" }} href="https://www.youtube.com/channel/UCVHOQO030wrqfdokc15lMfA" target={"_blank"} className="youtube"><i style={{ color: "white" }} className="bi bi-youtube"><span id="ytt"> </span></i> </a> &nbsp;&nbsp;<br></br>

          </div>
          <div style={{ width: "50px", height: "50px", backgroundColor: "#25D366", marginBottom: "2px" }} >
            <a style={{ fontSize: "34px", alignItems: "center", marginLeft: "10px" }} href="https://www.whatsapp.com/channel/0029Va7nCd59hXF4NvJAon0e" target={"_blank"} className="whatsapp"><i style={{ color: "white" }} className="bi bi-whatsapp"><span id="ytt"> </span></i> </a> &nbsp;&nbsp;<br></br>

          </div>
          {/* <a href="tel:8000305305" target="_blank" className="phone"> <i className="bi bi-phone" phone></i><span>Call Us:8000305305</span> </a> */}
          {/* <div class="phone"><a href="tel:8000305305" target="_blank"> <i className="bi bi-phone" phone></i><span>Call Us:8000305305</span> </a></div> */}
          {/* <div id="mySidenav" class="sidenav">
                <a href="tel:6352717211" target="_blank" id="about"> 6352717211 <i className="bi bi-phone"></i></a>
                </div> */}
        </div>

        {/* <a href="#" className="instagram"><i className="bi bi-instagram"></i></a> &nbsp;
            <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>&nbsp; */}



      </header>

      <Outlet />
    </>
  );
}
