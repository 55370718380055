import React from 'react';
// import Footer from './Footer'
import { useState } from 'react';
import { Helmet } from 'react-helmet';

export default function Sharada_revelatuion() {
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  return (
   <>
    <main id="main">
<section id="breadcrumbs" class="breadcrumbs1"></section>
    {/* <section id="hero">
      <img src="" alt="   image will be display here " />
    </section> */}
     <div>
        <Helmet>
  
            <title>Sharada Mathamnaya | शारदा मठाम्नायः </title>
            {/* <meta
                name="description"
                content="Get lyrics of every music for free"
            /> */}
        </Helmet>
        </div>
    
    <section id="contact" class="contact1">

      <div class="row faq-item d-flex align-items-stretch" id='nevil'>
      {/* <div class="container2">  style={{height:"1100px"}} */}
      <div class="text-center">
      <h1 style={{textalign:"center"}}> Sharada Mathamnaya </h1>
      </div>
      <div class="col-lg-6">
      
            <div class="info1">
              {/* <div class="text-center">
                <h1> ।। शारदा मठाम्नायः ।।  </h1>
              </div> */}

              <div class="text-center"><br></br>
                {/* <img src="assets/img/slide/slide-7.jpg" class="img-fluid" alt="" /> */}
                <p>
                  <b>
                    प्रथम: पश्चिमाम्नाय: शारदामठ उच्यते | <br></br>
                    कीटवार: सम्प्रदायस्तस्य तीर्थाश्रमी पदे ||१|| <br></br>
                  </b>
                  श्रीशङ्कराचार्य द्वारा स्थापित पहला आम्नाय पश्चिम दिशा में है, (जिसको) शारदामठ कहा जाता है । (इसका) कीटवार सम्प्रदाय है। तीर्थ और आश्रम (इसके संन्यासियों के) पद अर्थात् योगपट्ट हैं।
                </p>
              </div>

              <div class="text-center">

                <p>
                  <b>
                    द्वारकाख्यं हि क्षेत्रं स्याद् देवः सिद्धेश्वरः स्मृतः । <br></br>
                    भद्रकाली तु देवी स्यादाचार्यो विश्वरूपकः ||२|| <br></br>
                  </b>
                  इसके क्षेत्र का नाम द्वारका है, देवता सिद्धेश्वर है, देवी भद्रकाली हैं तथा (आदि) आचार्य विश्वरूप (सुरेश्वराचार्य) हैं ।

                </p>
              </div>
              <div class="text-center">

                <p>
                  <b>
                  गोमती तीर्थममलं ब्रह्मचारी स्वरूपकः  | <br></br>
                  सामवेदस्य वक्ता च तत्र धर्म समाचरेत् ||३|| <br></br>
                  </b>
                  निर्मल तीर्थ गोमती है, यहाँ स्वरूप नामक ब्रह्मचारी सामवेद का बक्ता कहा गया है, वह वहाँ धर्म का पालन करे। (इसका अर्थ यह है कि स्वरूप नामक योगपट्ट धारण करने वाले ब्रह्मचारी का मुख्य अध्ययन विषय सामवेद है ।)

                </p>
              </div>
              <div class="text-center">

                <p>
                  <b>
                  जीवात्मपरमात्मैक्यबोधो यत्र भविष्यति | <br></br>
                  तत्त्वमसि महावाक्यं गोत्रोऽविगत उच्यते ||४|| <br></br>
                  </b>
                  (इस आम्नाय का) महावाक्य 'तत्त्वमसि' है (जिसका उल्लेख सामवेदान्तर्गत 'छान्दोग्य उपनिषद् ६/८ में है)। यह जीवात्मा और परमात्मा के अभेद का बोध कराता है। इस मठ का गोत्र अविगत कहा जाता है।

                </p>
              </div>
              <div class="text-center">

                <p>
                  <b>
                  सिन्धु- सौवीर- सौराष्ट्र- महाराष्ट्रास्तथान्तराः |  <br></br>
                  देशाः पश्चिमदिवस्था ये शारदामठ भागिनः ||५|| <br></br>
                  </b>
                  सिन्धु, सौवीर (कच्छ), सौराष्ट्र (काठियावाड़) महाराष्ट्र और इनके मध्यवर्ती के) पश्चिम दिशा में स्थित प्रदेश शारदा मठ की अधिकार सीमा में हैं।
                </p>
              </div>
              <div class="text-center">
                <p>
                  <b>
                  त्रिवेणी सङ्गमे तीर्थे तत्त्वमस्यादि लक्षणे । <br></br>
                  स्नायात्तत्त्वार्थभावेन तीर्थनाम्ना स उच्यते ।।६।। <br></br>
                  </b>
                  जो तत् त्वम् असि (इस तीन पदात्मक) महावाक्यरूपी त्रिवेणी-सङ्गम में तीर्थ की भावना से स्नान करता है (अर्थात् तत् त्वम् पदों का शोधन करके महावाक्य के लक्ष्य अर्थ के चिन्तन में तल्लीन रहता है) उसे तीर्थ नाम से कहा जाता है
                </p>
              </div>
              <div class="text-center">
                <p>
                  <b>
                  आश्रमग्रहणे प्रौढ आशापाश-विवर्जितः | <br></br>
                  यातायात विनिर्मुक्त एव (ह्य्) आश्रम उच्यते ।।७।। <br></br>
                  </b>
                  संन्यास आश्रम (के नियमों के धारण) में दृढ, आशा के बन्धन (अर्थात् ऐहिक तथा पारलौकिक फलभोग) से रहित (संसार के) आवागमन से मुक्त संन्यासी ही आश्रम कहा जाता है।
                </p>
              </div>
              <div class="text-center">
                <p>
                  <b>
                  कीटादयो विशेषेण वार्यन्ते यत्र जन्तवः | <br></br>
                  भूतानुकम्पया नित्यं कीटवार: स उच्यते ।।८।।  <br></br>
                  </b>
                  जिसमें प्राणियों पर दया के कारण विशेष रूप से कीट आदि (क्षुद्र जन्तुओं) को सदा प्राणहानि से बचाया जाता है, वह सम्प्रदाय कीटवार कहा जाता है।
                </p>
              </div>
              <div class="text-center">
                <p>
                  <b>
                  स्व-स्वरूपं विजानाति स्वधर्मपरिपालकः । <br></br>
                  स्वानन्दे क्रीडते नित्यं स्वरूपो बटुरुच्यते ।।९।।<br></br>
                  </b>
                  जो अपने स्वरूप को भलीभाँति पहचानता है, स्वधर्म का परिपालन करता है, आत्मानन्द में नित्य क्रीड़ा करता है, (वह) ब्रह्मचारी स्वरूप कहा जाता है।
                </p>
              </div>
            {/* </div> */}
            {/* </div> */}


            {/* <i class="bx bx-help-circle"></i><h4>Tempus quam pellentesque nec nam aliquam sem et tortor consequat?</h4> */}
          </div>
          {/* </div> */}
      </div>
          {/* <div class="container3">   */}
        
                <div class="col-lg-6">
                {/* style={{height:"1100px",overflowY:"scroll"}} */}
                  {/* <h2 class="animate__animated animate__fadeInDown"><span>भूमिका</span></h2> */}
                
                  {/* <div class="text-center">
                    <h1> शारदा मठाम्नायः | Sharada Mathamnaya </h1>
                   </div> */}
               <img style={{width:"100%",padding:"10px"}} src="assets/img/slide/sharda-prakasham.jpeg" alt="शारदा मठाम्नायः" />
                  <p class="text-center" ><br></br>
                      <b>प्रथमः  पश्चिमाम्नायः शारदामठ उच्यते <br></br>
                        कीटवार: सम्प्रदायस्तस्य तीर्थाश्रमी पदे ||१| <br></br> </b> </p>
                    <p style={{textAlign:"justify"}}> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    First one Revelation receptacle in the West is called <b>'Sharada Math' </b> Denomination of its excellent ascetics is <b>Keetwar, Teerth and Ashram </b> are their title or natural glories <br></br></p>
                        <b>COMMENTARY </b>
                        <b>OBJECTION: </b> <h5>What do you mean by the word 'Amnaya' ? <br></br></h5>
                        <b>REPLY:</b> 
                        <p style={{textAlign:"justify"}}>The word <b>"Amnaya'</b> means <b>receptacle of Revelation (Shruti) </b> i.e. Vedic words and sentences. Amongst the <b>thousand names of the Lord Shiv "Amnaya" </b> also finds place (Shiv. Pu./Kotirudrasamhita/ Shiv. Sa. 779/Page 369) a synonyms of which <b> "Shrutisagar" </b> has been enumerated amongst the <b>thousand names of the Lord Vishnu</b> (Vis. Sa. Verse 41/Name 264). Shrutisagar means receptacle of Shruti says Acharya Shankar (Com. On ibid). According to Monier Williams it means- Sacred tradition, Sacred Texts handed down by repetition, Ved, received doctrine (his Dict. Page 147) similar meanings have also been given in opte's Dict. On Page 155, which requires some modification. According to them translation would be 'first Ved or Sacred Tradition or received doctrine in the West is called <b>"Sharada Monastery"</b> which is repugnant to the context.</p>  
                  
                    
                    <p style={{textAlign:"justify"}}>
                        <b>OBJECTION:<br></br> </b> <h5> Meaning of the word Pad wigen by you seems to be unfounded.<br></br></h5>
                        <b>REPLY:</b><br></br> According to Acharya Shankar- 'Pad' means that which is attained or known, hence it means only the nature of his glory, one should know that (Com. On Bri. Up. 4/4/23). You can translate is as 'Title' or 'Rank' also.<br></br> 
                        <br />
                        
                        <b>OBJECTION:<br></br></b> <h5>I fail to understand meaning of the word 'Sampradaya' as given by you.<br></br></h5>
                        <b>REPLY:</b><br></br> Generally the 'Word' Sampradaya means a denomination, sect or an order of ascetics. Since Acharya has said that only excellent ascetics or renouncers are entitled for the great motto of the Upanishads (Comm. On Svet. Up. 6/21) here it means the denomination or order of the excellent ascetics of renouncers. <br></br>
                        <br />
                        
                        {/* {isShowMore && (
                        <p>
                        <b>OBJECTION:</b> On what basis Acharya Shankar has termed Sharadamath Dwarka as 'Pratham Paschimamnaya'? <br></br>
                        <b>REPLY: </b> It puzzles minds of Scholars, Sages and Ascetics too, as to why a on what basis Acharya Shankar called Shardamath - Dwarka as first Receptacle. Let us get its answer for once and all. According to Sacred Texts on th basis of origin of the Vedas first one is Rig-Ved, Second one is Sam-Ved, third o is Atharv-Ved and fourth one is Yajur-Ved. (Rig 10/90/9, Atharv-19/6/13; Yaju-31 7), According to the Scripture from the Eastern mouth of Brahma Rig Ved, from the Southern mouth Yajur-Ved, from the western mouth Sam-Ved and from the Northern mouth Atharv-Ved were expressed, (Bhag. Pu.3/12/37), accordingly Acharya Shankar allotted Rig-Ved to the Goverdhanmath-Puri situated in the Eastern Quarter, Yajur Ved to the Shringerimath Situated in the Southern Quarter, Sam-Ved to the Shardamath-Dwarka situated in the Western Quarter and Atharv-Ved to the Jyotirmath Badarikashram situated in the Northern Quarter. Now Question arises that when Acharya allotted Rig-Ved to the Goverdhan Math why didn't he say that Goverdhan Math Puri is first Revelation receptacle Monastery instead he said Sharda Math - Dwaraka is first Revelation receptacle Monastery? We can find concrete solution of this problem in Srimad Bhagwat Gita wherein Lord Krishna Says: 'Among the Vedas I am Sam Veda' (ibid 10/22) From the aforesaid saying of the Supreme Lord it is crystal clear that among the Vedas Sam Veda is Superlative manifestation of the almighty, furthermore in Shiv Sahasranam "Samgeyapriya' has been enumerated as 779th name amongst thousand names of Lord Shiva; so also in Vish. Sa. Verse 75  
                        'TRISAM', 'SAMAG' and 'SAM' have been enumerated as 574 575 and 576th names of Lord Vishnu. Trisam means one who is praised by the Chanters of Sam-gan through the three Sam known as Devavrata; Samag means one who chants the Sam-gan and, SAM means Samved (Comm. On ibid), among Sam Lord is Brihat Sam (Comm. On Bhag. Gita 10/35); Among the Vedas Lord Shiv is Sam Ved (Mah./Anu/Ch: 14/Verse 323; Ku Pu upari Vibhag/Ch: 7/verse12) It has also been said that Atharved is head, Sam Ved is mouth, Rig-Ved is eyes and Yajur Ved is hands as well as feet of the Lord Shiv (Mah./Anu/Ch: 17/-Verse 91 & 92) <br></br> <br />
                        It is revelation (Shruti) that one who meditates on 'Aum' Knowing its first letter 'A' which is identical with the Rich Mantras, after death Richas cause him immediate human birth of prominent Bhrahman; knowing its second letter 'u' which is identical with the Yajur Mantras, after death Yajur leads him to a birth in the world of Moon; and knowing its third letter 'm' which is identical with the Sam Mantras becoming free from sin after the death he is lifted up by the Sam to the world of Brahma i.e. Hiranyagarbha, which is called Satya (Comm. On Pra. Up. 5/3 to 5) Rich mantras stand for Rig-Ved (ibid 5/3) as such Yajur and Sam Mantras stand for Yajur Ved and Sam-Ved respectively. The enlightened Kavi and Medhavi,
                        not the ignorants know that through the Rig Ved this world of Human, through the Ya Ved the world of Moon and through the Sam Ved the world of Brahma are attalinest (Commm On a Up. 57. One who is possessed of intuitive experience, characterised as knowledge of the Self and whose doubts created by ignorance have been sundered as well as who is freed from doubts by his firm conviction that nothing but abiding in the true nature of the Self is the Supreme means to the Highest good is called MEDHAVI (Comm. On Bhag Gita 18/10) Which Facts substantiate superiority of Sam-Ved, as such according to the superiority of the vedas Acharya Shankar has recounted his monasteries.<br></br> 
                        <p class="text-center"><b>द्वारकाख्यं हि क्षेत्रं स्याद् देवः सिद्धेश्वरः स्मृतः । <br></br> भद्रकाली तु देवी स्यादाचार्यो विश्वरूपकः ||२|| </b> </p> Realm is Dwaraka God is Siddheshwar. Goddess is Bhadrakali and (first) Acharya is Vishwaroop (02) 
                        <p class="text-center"><b>गोमती तीर्थममलं ब्रह्मचारी स्वरूपकः | <br></br> सामवेदस्य वक्ता च तत्र धर्मं समाचरेत् ।।३।। </b></p> Sacred Stream is Gomati. Swaroop (Titled) celibate is speaker of the Samveda. There, Dharm be followed by him righteously. (03)                            
                        <p class="text-center"><b>जीवात्मपरमात्मैक्यबोधो यत्र भविष्यति| <br></br> तत्त्वमसि महावाक्यं गोत्रोऽविगत उच्यते उच्यते ।।४।। </b></p> Great motto is 'Tat tvam Asi' ie. That thou art' (Chha. Up. 6/8/7), here derives knowledge of oneness in the Self and Supreme Self. Gotra is Avigat. (04) 
                        <p class="text-center"><b>सिन्धु- सौवीर- सौराष्ट्र- महाराष्ट्रास्तथान्तराः |<br></br> देशाः पश्चिमदिवस्था ये शारदामठ भागिनः ||५|| </b></p> Territories Situated in the western Direction bounded by Sindhu, Sauvir, Saurashatra and Maharashtra are apportionment of the Sharada Math. (05) 
                        <p class="text-center"><b>त्रिवेणी सङ्गमे तीर्थे तत्त्वमस्यादि लक्षणे । <br></br> स्नायात्तत्त्वार्थभावेन तीर्थनाम्ना स उच्यते ।।६।। </b></p>  Who takes holy-dip in the great Motto "Tat, Twam, Asi' as in the sacred stream of the three rivers' confluence with the passion of the knowledge of Reality is termed as Teerth. (06)
                        <p class="text-center"><b>आश्रमग्रहणे प्रौढ आशापाशविवर्जितः|<br></br> यातायातविनिर्मुक्त एव (ह्या) आश्रम उच्यते ||७| </b></p>
                        The Extreme renouncer steadfast in adoption of ascetic Ashram, excluded from the Shackles in the from of hope as well as free from being subject to life and death, is termed as Ashram. (07)
                        <p class="text-center"><b>कीटादयों विशेषेण वार्यन्ते जन्तवः |<br></br>भूतानुकम्पया नित्यं कीटवारः यत्र स उच्यते ||८|| </b></p>
                          <p style={{textAlign:"justify"}}>Denomination of the excellent renouncers that always bestows mercy upon creatures; precludes the worldly people having non-discriminating wisdom, whose acts are prompted by natural impulses that are repugnant to the scripture from becoming little creatures which are constantly subject to birth and death by making their discriminating wisdom free from the ignorance created by antagonism of desire and repulsion; is called Keetwar. (08) <br></br></p>
                          <br />
                          <p style={{textAlign:"justify"}}>
                          <b>COMMENTARY:</b><br></br> In the above verse meanings of the word "Keet and Jantavah' have been given as "insect' and 'small creatures' respectively by some scholars, which prima- facie appears contrary to the context as because the main object of the renouncers is to extend Brahm Vidya and cause the people to attain salvation. <br></br> Acharya Shankar says where the meaning of a word is doubtful it can be ascertained by a reference to definite use of the word else-where, or from a supplementary statement; or else on the strength of irrefutable logic. (Comm. On Bri. Up 4/3/7) <br></br> As such we can ascertain meaning of the word 'Jantavah' and 'Keet' by reference to Srimad Bhagwat Gita Chapter 5/ verse 15 and à Sanyasopanishad / verse 30. Acharya Shankar in his Commentary on said verse of Gita says, 'Jantavah' means the non-discriminating people in the world whose 'Inanam' means discriminating wisdom remains covered 'Ajnanen' means by ignorance, a 'Ten' means thereby 'Muhyanti' means becomes deluded. <br></br> Above mentioned verse of the Samnyasopanishad reads as follows: <br></br>'By delusion of antagonism created by desire and repulsion non- discriminating people of the world met with similar fate on this earth as the insects in fireplace.<br></br>
                            Person who performs rites. i.e. fire oblation etc. goes to the world of Manes through the Southern path and who Meditates on Gods goes to the world of Gods through the Northern path (Acharya Shankara's Commentary on -Ken. Up introduction; Isha. Up. 19; Bri. Up. 1/5/16 & Chh. Up. 1/5/7 & 8). The persons who neither perform rites nor meditation furthermore whose actions are prompted by natural impulses repugnant to the scriptures, by neither of these two paths, do they go. They keep repeatedly revolving, being born as small creatures as stinging insects, insects, mosquitoes etc. subject to saying 'Be born and die'. This is the third state. (Acharya Shankar's Comm. On Chha. Up. 1/5/8 and its introduction; ken. Up/Introduction). <br></br>Therefore on the basis of use of "Jantavah" and "Keet' in the aforesaid Texts and commentaries of Acharya himself, it is concluded that here the word Jantavah' means -Non-discriminating people in the world whose discriminating wisdom is covered by delusion or ignorance of antagonism created by desire and repulsion, as also the world 'Keet' means -Persons whose acts are prompted by natural impulses that are repugnant to the scriptures i.e. the persons of unchecked behavior in consequence whereof they become little creatures i.e. Keets (insects) which are constantly subject to birth and death. 
                          </p>
                            <p class="text-center"><b> स्वस्वरूपं  स्वधर्मपरिपालकः । <br></br> स्वानन्दे क्रीडते  नित्यं स्वरूपो बटुरुच्यते ।।९।। </b></p><p style={{textAlign:"justify"}}>  One who knows one's self properly, complies One's Dharma and amuses in one's self-delight that Brahmachari (celibate) is termed as Swaroop (09) </p>
                      
                  </p>
              )}
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <button onClick={toggleReadMoreLess} className="btn btn-primary"> {isShowMore ? "Read Less" : "Read More"} </button> 
              </div> */}
              </p>
                
                        {/* <b>OBJECTION:</b> On what basis Acharya Shankar has termed Sharadamath Dwarka as 'Pratham Paschimamnaya'?  */}
                        {/* <b>REPLY: </b> It puzzles minds of Scholars, Sages and Ascetics too, as to why a on what basis Acharya Shankar called Shardamath - Dwarka as first Receptacle. Let us get its answer for once and all. According to Sacred Texts on th basis of origin of the Vedas first one is Rig-Ved, Second one is Sam-Ved, third o is Atharv-Ved and fourth one is Yajur-Ved. (Rig 10/90/9, Atharv-19/6/13; Yaju-31 7), According to the Scripture from the Eastern mouth of Brahma Rig Ved, from the Southern mouth Yajur-Ved, from the western mouth Sam-Ved and from the Northern mouth Atharv-Ved were expressed, (Bhag. Pu.3/12/37), accordingly Acharya Shankar allotted Rig-Ved to the Goverdhanmath-Puri situated in the Eastern Quarter, Yajur Ved to the Shringerimath Situated in the Southern Quarter, Sam-Ved to the Shardamath-Dwarka situated in the Western Quarter and Atharv-Ved to the Jyotirmath Badarikashram situated in the Northern Quarter. Now Question arises that when Acharya allotted Rig-Ved to the Goverdhan Math why didn't he say that Goverdhan Math Puri is first Revelation receptacle Monastery instead he said Sharda Math - Dwaraka is first Revelation receptacle Monastery? We can find concrete solution of this problem in Srimad Bhagwat Gita wherein Lord Krishna Says: 'Among the Vedas I am Sam Veda' (ibid 10/22) From the aforesaid saying of the Supreme Lord it is crystal clear that among the Vedas Sam Veda is Superlative manifestation of the almighty, furthermore in Shiv Sahasranam "Samgeyapriya' has been enumerated as 779th name amongst thousand names of Lord Shiva; so also in Vish. Sa. Verse 75  
                        'TRISAM', 'SAMAG' and 'SAM' have been enumerated as 574 575 and 576th names of Lord Vishnu. Trisam means one who is praised by the Chanters of Sam-gan through the three Sam known as Devavrata; Samag means one who chants the Sam-gan and, SAM means Samved (Comm. On ibid), among Sam Lord is Brihat Sam (Comm. On Bhag. Gita 10/35); Among the Vedas Lord Shiv is Sam Ved (Mah./Anu/Ch: 14/Verse 323; Ku Pu upari Vibhag/Ch: 7/verse12) It has also been said that Atharved is head, Sam Ved is mouth, Rig-Ved is eyes and Yajur Ved is hands as well as feet of the Lord Shiv (Mah./Anu/Ch: 17/-Verse 91 & 92) <br></br> <br />
                        It is revelation (Shruti) that one who meditates on 'Aum' Knowing its first letter 'A' which is identical with the Rich Mantras, after death Richas cause him immediate human birth of prominent Bhrahman; knowing its second letter 'u' which is identical with the Yajur Mantras, after death Yajur leads him to a birth in the world of Moon; and knowing its third letter 'm' which is identical with the Sam Mantras becoming free from sin after the death he is lifted up by the Sam to the world of Brahma i.e. Hiranyagarbha, which is called Satya (Comm. On Pra. Up. 5/3 to 5) Rich mantras stand for Rig-Ved (ibid 5/3) as such Yajur and Sam Mantras stand for Yajur Ved and Sam-Ved respectively. The enlightened Kavi and Medhavi,
                        not the ignorants know that through the Rig Ved this world of Human, through the Ya Ved the world of Moon and through the Sam Ved the world of Brahma are attalinest (Commm On a Up. 57. One who is possessed of intuitive experience, characterised as knowledge of the Self and whose doubts created by ignorance have been sundered as well as who is freed from doubts by his firm conviction that nothing but abiding in the true nature of the Self is the Supreme means to the Highest good is called MEDHAVI (Comm. On Bhag Gita 18/10) Which Facts substantiate superiority of Sam-Ved, as such according to the superiority of the vedas Acharya Shankar has recounted his monasteries.<br></br> 
                                                  */}
                        {/* <p class="text-center"><b>द्वारकाख्यं हि क्षेत्रं स्याद् देवः सिद्धेश्वरः स्मृतः । <br></br> भद्रकाली तु देवी स्यादाचार्यो विश्वरूपकः ||२|| </b> </p> Realm is Dwaraka God is Siddheshwar. Goddess is Bhadrakali and (first) Acharya is Vishwaroop (02)  */}
                        {/* <p class="text-center"><b>गोमती तीर्थममलं ब्रह्मचारी स्वरूपकः | <br></br> सामवेदस्य वक्ता च तत्र धर्मं समाचरेत् ।।३।। </b></p> Sacred Stream is Gomati. Swaroop (Titled) celibate is speaker of the Samveda. There, Dharm be followed by him righteously. (03)  */}
                        {/* <p class="text-center"><b>जीवात्मपरमात्मैक्यबोधो यत्र भविष्यति| <br></br> तत्त्वमसि महावाक्यं गोत्रोऽविगत उच्यते उच्यते ।।४।। </b></p> Great motto is 'Tat tvam Asi' ie. That thou art' (Chha. Up. 6/8/7), here derives knowledge of oneness in the Self and Supreme Self. Gotra is Avigat. (04)  */}
                        {/* <p class="text-center"><b>सिन्धु- सौवीर- सौराष्ट्र- महाराष्ट्रास्तथान्तराः |<br></br> देशाः पश्चिमदिवस्था ये शारदामठ भागिनः ||५|| </b></p> Territories Situated in the western Direction bounded by Sindhu, Sauvir, Saurashatra and Maharashtra are apportionment of the Sharada Math. (05)  */}
                        
                        {/* <p class="text-center"><b>आश्रमग्रहणे प्रौढ आशापाशविवर्जितः|<br></br> यातायातविनिर्मुक्त एव (ह्या) आश्रम उच्यते ||७| </b></p> 
                            The Extreme renouncer steadfast in adoption of ascetic Ashram, excluded from the Shackles in the from of hope as well as free from being subject to life and death, is termed as Ashram. (07) */}
                        {/* <p class="text-center"><b>कीटादयों विशेषेण वार्यन्ते जन्तवः |<br></br>भूतानुकम्पया नित्यं कीटवारः यत्र स उच्यते ||८|| </b></p>
                          <p style={{textAlign:"justify"}}>Denomination of the excellent renouncers that always bestows mercy upon creatures; precludes the worldly people having non-discriminating wisdom, whose acts are prompted by natural impulses that are repugnant to the scripture from becoming little creatures which are constantly subject to birth and death by making their discriminating wisdom free from the ignorance created by antagonism of desire and repulsion; is called Keetwar. (08) <br></br></p>
                          <br /> */}
                          {/* <p style={{textAlign:"justify"}}>
                          <b>COMMENTARY:</b><br></br> In the above verse meanings of the word "Keet and Jantavah' have been given as "insect' and 'small creatures' respectively by some scholars, which prima- facie appears contrary to the context as because the main object of the renouncers is to extend Brahm Vidya and cause the people to attain salvation. <br></br> Acharya Shankar says where the meaning of a word is doubtful it can be ascertained by a reference to definite use of the word else-where, or from a supplementary statement; or else on the strength of irrefutable logic. (Comm. On Bri. Up 4/3/7) <br></br> As such we can ascertain meaning of the word 'Jantavah' and 'Keet' by reference to Srimad Bhagwat Gita Chapter 5/ verse 15 and à Sanyasopanishad / verse 30. Acharya Shankar in his Commentary on said verse of Gita says, 'Jantavah' means the non-discriminating people in the world whose 'Inanam' means discriminating wisdom remains covered 'Ajnanen' means by ignorance, a 'Ten' means thereby 'Muhyanti' means becomes deluded. <br></br> Above mentioned verse of the Samnyasopanishad reads as follows: <br></br>'By delusion of antagonism created by desire and repulsion non- discriminating people of the world met with similar fate on this earth as the insects in fireplace.<br></br>
                            Person who performs rites. i.e. fire oblation etc. goes to the world of Manes through the Southern path and who Meditates on Gods goes to the world of Gods through the Northern path (Acharya Shankara's Commentary on -Ken. Up introduction; Isha. Up. 19; Bri. Up. 1/5/16 & Chh. Up. 1/5/7 & 8). The persons who neither perform rites nor meditation furthermore whose actions are prompted by natural impulses repugnant to the scriptures, by neither of these two paths, do they go. They keep repeatedly revolving, being born as small creatures as stinging insects, insects, mosquitoes etc. subject to saying 'Be born and die'. This is the third state. (Acharya Shankar's Comm. On Chha. Up. 1/5/8 and its introduction; ken. Up/Introduction). <br></br>Therefore on the basis of use of "Jantavah" and "Keet' in the aforesaid Texts and commentaries of Acharya himself, it is concluded that here the word Jantavah' means -Non-discriminating people in the world whose discriminating wisdom is covered by delusion or ignorance of antagonism created by desire and repulsion, as also the world 'Keet' means -Persons whose acts are prompted by natural impulses that are repugnant to the scriptures i.e. the persons of unchecked behavior in consequence whereof they become little creatures i.e. Keets (insects) which are constantly subject to birth and death. 
                          </p>
                            <p class="text-center"><b> स्वस्वरूपं  स्वधर्मपरिपालकः । <br></br> स्वानन्दे क्रीडते  नित्यं स्वरूपो बटुरुच्यते ।।९।। </b></p><p style={{textAlign:"justify"}}>  One who knows one's self properly, complies One's Dharma and amuses in one's self-delight that Brahmachari (celibate) is termed as Swaroop (09) </p> */}
                    {/* <p class="text-center"> <b> शेषं गाथास्त्रिभिः षद्भिश्चरणैश्चोपलक्षिताः । १/१८ । </b></p>
                    <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;इसी प्रकार जिस अनुष्टुप् छन्दों में एक पाद में ७ अथवा ९ वर्ण हो जाते हैं उनकी भी गाथा-संज्ञा है । उन छन्दों को अशुद्ध नहीं कहा जा सकता। यथा – <br></br>
                        <p class="text-center"><b>विषमाक्षरपादं वा पादैरसमं दशधर्मवत् । <br></br>
                        यच्छन्दो नोक्तमत्र गाथेति तत्सूरिभिः प्रोक्तम् ।। </b> वृत्तरत्नाकर ५/१२ । <br></br> </p>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;जिनको लोग छन्दोऽशुद्धि मानते हैं, वह वस्तुतः आचार्य चरणों का छन्दः शास्त्रवैशारद्य ही है, अज्ञान नहीं । <br></br>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;अतः यही सिद्ध होता है कि इस ग्रन्थ में अशुद्धियाँ नहीं हैं, अपितु ऋषि-परम्परा का निर्वाह आर्षप्रयोगों द्वारा किया गया है। इस प्रकार इन आधारों पर 'मठाम्नाय महानुशासन' का आद्यशङ्कराचार्य को रचयिता न मानना और इसकी अप्रामाणिकता की घोषणा दोनों ही असिद्ध हो जाते हैं।
        </p> */}
              
                </div>
                <div>
                       <p style={{textAlign:"justify"}}>
                        <b>OBJECTION:</b><br></br> <h5>On what basis Acharya Shankar has termed Sharadamath Dwarka as 'Pratham Paschimamnaya'? <br></br></h5>
                        <b>REPLY: </b><br></br> It puzzles minds of <b>Scholars, Sages and Ascetics </b> too, as to why a on what basis Acharya Shankar called <b>Shardamath - Dwarka</b> as first Receptacle. Let us get its answer for once and all. According to Sacred Texts on th basis of origin of the Vedas first one is <b>Rig-Ved</b> , Second one is <b>Sam-Ved,</b> third o is <b>Atharv-Ved</b> and fourth one is <b>Yajur-Ved</b>. (Rig 10/90/9, Atharv-19/6/13; Yaju-31 7), According to the Scripture from the Eastern mouth of Brahma Rig Ved, from the Southern mouth Yajur-Ved, from the western mouth Sam-Ved and from the Northern mouth Atharv-Ved were expressed, (Bhag. Pu.3/12/37), accordingly Acharya Shankar allotted Rig-Ved to the <b>Goverdhanmath-Puri</b> situated in the Eastern Quarter, Yajur Ved to the <b>Shringerimath</b> Situated in the Southern Quarter, Sam-Ved to the <b>Shardamath-Dwarka</b> situated in the Western Quarter and Atharv-Ved to the <b>Jyotirmath Badarikashram </b> situated in the Northern Quarter. Now Question arises that when Acharya allotted Rig-Ved to the Goverdhan Math why didn't he say that Goverdhan Math Puri is first Revelation receptacle Monastery instead he said Sharda Math - Dwaraka is first Revelation receptacle Monastery? We can find concrete solution of this problem in Srimad Bhagwat Gita wherein Lord Krishna Says: <b>'Among the Vedas I am Sam Veda'</b> (ibid 10/22) From the aforesaid saying of the Supreme Lord it is crystal clear that among the Vedas Sam Veda is Superlative manifestation of the almighty, furthermore in Shiv Sahasranam "Samgeyapriya' has been enumerated as 779th name amongst thousand names of Lord Shiva; so also in Vish. Sa. Verse 75  
                        <b>'TRISAM', 'SAMAG' and 'SAM'</b> have been enumerated as 574 575 and 576th names of Lord Vishnu. Trisam means one who is praised by the Chanters of Sam-gan through the three Sam known as Devavrata; Samag means one who chants the Sam-gan and, SAM means Samved (Comm. On ibid), among Sam Lord is Brihat Sam (Comm. On Bhag. Gita 10/35); Among the Vedas Lord Shiv is Sam Ved (Mah./Anu/Ch: 14/Verse 323; Ku Pu upari Vibhag/Ch: 7/verse12) It has also been said that Atharved is head, Sam Ved is mouth, Rig-Ved is eyes and Yajur Ved is hands as well as feet of the Lord Shiv (Mah./Anu/Ch: 17/-Verse 91 & 92) <br></br> <br />
                        It is revelation (Shruti) that one who meditates on <b>'Aum'</b> Knowing its first letter 'A' which is identical with the Rich Mantras, after death Richas cause him immediate human birth of prominent Bhrahman; knowing its second letter 'u' which is identical with the Yajur Mantras, after death Yajur leads him to a birth in the world of Moon; and knowing its third letter 'm' which is identical with the Sam Mantras becoming free from sin after the death he is lifted up by the Sam to the world of Brahma i.e. Hiranyagarbha, which is called Satya (Comm. On Pra. Up. 5/3 to 5) Rich mantras stand for Rig-Ved (ibid 5/3) as such Yajur and Sam Mantras stand for Yajur Ved and Sam-Ved respectively. The enlightened Kavi and Medhavi,
                        not the ignorants know that through the <b> Rig Ved this world of Human</b>, through the Ya Ved the world of Moon and through the <b> Sam Ved the world of Brahma  are attalinest</b> (Commm On a Up. 57. One who is possessed of intuitive experience, characterised as knowledge of the Self and whose doubts created by ignorance have been sundered as well as who is freed from doubts by his firm conviction that nothing but abiding in the true nature of the Self is the Supreme means to the Highest good is called MEDHAVI (Comm. On Bhag Gita 18/10) Which Facts substantiate superiority of Sam-Ved, as such according to the superiority of the vedas Acharya Shankar has recounted his monasteries.<br></br><br></br> 
                        <div class="row">
                        <div class="col-md-4 " style={{backgroundcolor:"lightgrey", height:"auto",border:"2px solid  #EEE9DA",padding: "10px"}}>
                        <p class="text-center"><b>द्वारकाख्यं हि क्षेत्रं स्याद् देवः सिद्धेश्वरः स्मृतः । <br></br> भद्रकाली तु देवी स्यादाचार्यो विश्वरूपकः ||२|| </b> </p> Realm is Dwaraka God is Siddheshwar. Goddess is Bhadrakali and (first) Acharya is Vishwaroop (02) 
                         </div>
                         <div class="col-md-4 " style={{backgroundcolor:"lightgrey", height:"auto",border:"2px solid  #EEE9DA",padding: "10px"}}>
                         <p class="text-center"><b>गोमती तीर्थममलं ब्रह्मचारी स्वरूपकः | <br></br> सामवेदस्य वक्ता च तत्र धर्मं समाचरेत् ।।३।। </b></p> Sacred Stream is Gomati. Swaroop (Titled) celibate is speaker of the Samveda. There, Dharm be followed by him righteously. (03)  
                         </div>
                         <div class="col-md-4 " style={{backgroundcolor:"lightgrey", height:"auto",border:"2px solid  #EEE9DA",padding: "10px"}}>
                         <p class="text-center"><b>जीवात्मपरमात्मैक्यबोधो यत्र भविष्यति| <br></br> तत्त्वमसि महावाक्यं गोत्रोऽविगत उच्यते उच्यते ।।४।। </b></p> Great motto is 'Tat tvam Asi' ie. That thou art' (Chha. Up. 6/8/7), here derives knowledge of oneness in the Self and Supreme Self. Gotra is Avigat. (04) 
                         </div> 
                        </div>
                        <div class="row">
                        <div class="col-md-4 " style={{backgroundcolor:"lightgrey", height:"auto",border:"2px solid  #EEE9DA",padding: "10px"}}>
                        <p class="text-center"><b>सिन्धु- सौवीर- सौराष्ट्र- महाराष्ट्रास्तथान्तराः |<br></br> देशाः पश्चिमदिवस्था ये शारदामठ भागिनः ||५|| </b></p> Territories Situated in the western Direction bounded by Sindhu, Sauvir, Saurashatra and Maharashtra are apportionment of the Sharada Math. (05) 
                        </div>
                        <div class="col-md-4 " style={{backgroundcolor:"lightgrey", height:"auto",border:"2px solid  #EEE9DA",padding: "10px"}}>
                        <p class="text-center"><b>त्रिवेणी सङ्गमे तीर्थे तत्त्वमस्यादि लक्षणे । <br></br> स्नायात्तत्त्वार्थभावेन तीर्थनाम्ना स उच्यते ।।६।। </b></p>  Who takes holy-dip in the great Motto "Tat, Twam, Asi' as in the sacred stream of the three rivers' confluence with the passion of the knowledge of Reality is termed as Teerth. (06)
                        </div>
                        <div class="col-md-4 " style={{backgroundcolor:"lightgrey", height:"auto",border:"2px solid  #EEE9DA",padding: "10px"}}>
                        <p class="text-center"><b>आश्रमग्रहणे प्रौढ आशापाशविवर्जितः|<br></br> यातायातविनिर्मुक्त एव (ह्या) आश्रम उच्यते ||७| </b></p>
                        The Extreme renouncer steadfast in adoption of ascetic Ashram, excluded from the Shackles in the from of hope as well as free from being subject to life and death, is termed as Ashram. (07)
                        </div>

                        </div><br></br>
                       <p class="text-center"><b>कीटादयों विशेषेण वार्यन्ते जन्तवः |<br></br>भूतानुकम्पया नित्यं कीटवारः यत्र स उच्यते ||८|| </b></p>
                          <p style={{textAlign:"justify"}}>Denomination of the excellent renouncers that always bestows mercy upon creatures; precludes the worldly people having non-discriminating wisdom, whose acts are prompted by natural impulses that are repugnant to the scripture from becoming little creatures which are constantly subject to birth and death by making their discriminating wisdom free from the ignorance created by antagonism of desire and repulsion; is called Keetwar. (08) <br></br></p>
                          
                          <p style={{textAlign:"justify"}}>
                          <b>COMMENTARY:</b><br></br>
                          <p> In the above verse meanings of the word "Keet and Jantavah' have been given as "insect' and 'small creatures' respectively by some scholars, which prima- facie appears contrary to the context as because the main object of the renouncers is to extend Brahm Vidya and cause the people to attain salvation.</p>
                          <p> Acharya Shankar says where the meaning of a word is doubtful it can be ascertained by a reference to definite use of the word else-where, or from a supplementary statement; or else on the strength of irrefutable logic. (Comm. On Bri. Up 4/3/7)</p> 
                          <p> As such we can ascertain meaning of the word 'Jantavah' and 'Keet' by reference to Srimad Bhagwat Gita Chapter 5/ verse 15 and à Sanyasopanishad / verse 30. Acharya Shankar in his Commentary on said verse of Gita says, 'Jantavah' means the non-discriminating people in the world whose 'Inanam' means discriminating wisdom remains covered 'Ajnanen' means by ignorance, a 'Ten' means thereby 'Muhyanti' means becomes deluded.</p> 
                          <h4>Above mentioned verse of the Samnyasopanishad reads as follows:</h4> 
                          <p>'By delusion of antagonism created by desire and repulsion non- discriminating people of the world met with similar fate on this earth as the insects in fireplace.</p>
                          <p>Person who performs rites. i.e. fire oblation etc. goes to the world of Manes through the Southern path and who Meditates on Gods goes to the world of Gods through the Northern path (Acharya Shankara's Commentary on -Ken. Up introduction; Isha. Up. 19; Bri. Up. 1/5/16 & Chh. Up. 1/5/7 & 8). The persons who neither perform rites nor meditation furthermore whose actions are prompted by natural impulses repugnant to the scriptures, by neither of these two paths, do they go. They keep repeatedly revolving, being born as small creatures as stinging insects, insects, mosquitoes etc. subject to saying 'Be born and die'. This is the third state. (Acharya Shankar's Comm. On Chha. Up. 1/5/8 and its introduction; ken. Up/Introduction).</p><p>Therefore on the basis of use of "Jantavah" and "Keet' in the aforesaid Texts and commentaries of Acharya himself, it is concluded that here the word Jantavah' means -Non-discriminating people in the world whose discriminating wisdom is covered by delusion or ignorance of antagonism created by desire and repulsion, as also the world 'Keet' means -Persons whose acts are prompted by natural impulses that are repugnant to the scriptures i.e. the persons of unchecked behavior in consequence whereof they become little creatures i.e. Keets (insects) which are constantly subject to birth and death. </p>
                          </p>
                            <p class="text-center"><b> स्वस्वरूपं  स्वधर्मपरिपालकः । <br></br> स्वानन्दे क्रीडते  नित्यं स्वरूपो बटुरुच्यते ।।९।। </b></p><p style={{textAlign:"justify"}}>  One who knows one's self properly, complies One's Dharma and amuses in one's self-delight that Brahmachari (celibate) is termed as Swaroop (09) </p>
                      
                  </p>
              {/* )} */}
              {/* <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
              <button onClick={toggleReadMoreLess} className="btn btn-primary"> {isShowMore ? "Read Less" : "Read More"} </button> 
              </div> */}
              {/* </p> */}

                </div>
               
          {/* </div> */}
      </div>
      
    </section>
    {/* <section id="contact" class="contact"> */}
    {/* <div class="row faq-item d-flex align-items-stretch"><div class="col-lg-5">
      <div class="pic"><img src="assets/img/slide/slide-10.jpg" class="img-fluid" alt="" /></div> */}
      {/* <i class="bx bx-help-circle"></i><h4>Tempus quam pellentesque nec nam aliquam sem et tortor consequat?</h4> */}
    {/* </div>
      <div class="col-lg-7">
        <p>शारदा (कालिका) मठ गुजरात में द्वारकाधाम में स्थित है।
          शारदा मठ के अंतर्गत दीक्षा प्राप्त करने वाले सन्यासियों के नाम के
          बाद 'तीर्थ' और 'आश्रम' सम्प्रदाय नाम विशेषण लगाया जाता है जिससे उन्हें उक्त संप्रदाय का संन्यासी माना जाता है।
          इस मठ का महावाक्य है 'तत्त्वमसि' तथा इसके अंतर्गत 'सामवेद' को रखा गया है। शारदा मठ के प्रथम मठाधीश हस्तामलक (पृथ्वीधर) थे। हस्तामलक शंकराचार्य जी के प्रमुख चार शिष्यों में से एक थे।</p>

      </div>
    </div> */}
    {/* </section> */}
  

  </main>
   </>
  )
}
