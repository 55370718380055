import React from 'react';

export default function Footer() {
return (
    <footer id="footer">
         <div class="footer-top">
         <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-2 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="./">मुख्य पृष्ठ</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="./Introduction_Swami_shreeswarupanand">स्वामिश्रीस्वरूपानन्द</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="./Sharada_revelatuion"> शारदा प्रकाशनम्</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="./History_of_Sri_Sharadapeetham_Dwarka">इतिहास</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="./Shankaracharya_opinion">शङ्कराचार्य जी का मत</a></li>
            </ul>
          </div>

           <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4><a href="/About_Adi_Shankaracharya"><b>आदि शंकराचार्य के बारे में</b></a></h4>
            <p style={{fontWeight:"100",textAlign:"justify",fontSize:"14px"}}>
            वैदिक सनातन धर्म की पुनर्स्थापना करने वाले महान दार्शनिक आदि शंकराचार्य
                  का जन्म भारत के केरल प्रांत में एर्णाकुलम जिले के ग्राम काल्टी में ईसा
                  पूर्व सन् 507 वैशाख शु 5 के दिन हुआ था। उनके पिता का नाम
                  शिवगुरू तथा माता का नाम आर्याम्बा था। 8 वर्ष की आयु में
                  पूज्यपाद गोविन्द भगवत्पाद से कार्तिक शु0 11 को सन्यास ग्रहण किया।
                  उन्होंने प्रस्थानत्रयी आदि पर भाष्यों की रचना की। 
              </p>
            </div>
          <div class="col-lg-3 col-md-6">
            <div class="footer-info">
              <h3>शारदा पीठ द्वारका</h3>
                <strong>Address:- </strong>
                Shrishardapeetham Dwarka, Dwarka, Gujarat 361335 <br />
                <strong>Phone:</strong> 02892-235109  <br />
                <strong>Email:</strong> <a href="mailto:shardapithmathdwarka@gmail.com">shardapithmathdwarka@gmail.com</a>
             
              <div class="social-links mt-3">
                <a href="https://twitter.com/Dwarkamath" target={"_blank"} class="twitter"><i class="bx bxl-twitter"></i></a>
                <a href="https://www.facebook.com/shrisharadapeetham/" target={"_blank"} class="facebook"><i class="bx bxl-facebook"></i></a>
                {/* <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
                <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
                <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> */}
              </div>
            </div>
            
          </div>
          <div class="col-lg-3 col-md-6">
          <a href="https://goo.gl/maps/gjVtSpL5RFv2ugws6" target={"_blank"}>
            <iframe style={{ width: "100%", height:"200px",overflow:"hidden" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1846.529832824097!2d68.96626595143485!3d22.237814864579796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39569c3ac6424e11%3A0xa2f329ab3541a17!2sShrishardapeetham%20Dwarka!5e0!3m2!1sen!2sus!4v1676534019969!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </a>
           
            </div>

        </div>
      </div>
    </div>

    <div class="container">
      {/* <div class="copyright">
        &copy; Copyright <strong><span>Leewaysoftech</span></strong>. All Rights Reserved
      </div> */}
      <div class="credits">
        Designed by <a href="https://www.leewaysoftech.com" target={"_blank"}>leewaysoftech</a>
      </div>
    
    </div>
   
  </footer>
  );
}
