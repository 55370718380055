import React from "react";
import Footer from "./Footer";

export default function Privacypolicy(){
    return(
     <>
        <div>
            <h1>Privacy Policy</h1>
            <h1>Privacy Policy</h1>
            <h1>Privacy Policy</h1>
            <h1>Privacy Policy</h1>
            <h1>Privacy Policy</h1>
            <h1>Privacy Policy</h1>
            <h1>Privacy Policy</h1>
        </div>
     </>
    )
}