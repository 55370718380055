import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ReactPlayer from 'react-player';
import { Helmet } from "react-helmet";

export default function Video() {
  const [video, setVideo] = useState([]);
  let i = 1;

  useEffect(() => {

    fatchUsers1();

  }, []);
  const fatchUsers1 = async () => {
    try {
      const res = await axios.get("/video");
      setVideo(res.data);
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <section id="breadcrumbs" class="breadcrumbs1">
      <div>
        <Helmet>
  
            <title> VIDEO: वीडियो | Shree Shardapith Dwarka </title>
            {/* <meta
                name="description"
                content="Get lyrics of every music for free"
            /> */}
        </Helmet>
        </div>

        <section id="contact" class="contact1">
       
          <div>
            {/* <h1> Under construction  </h1> */}
            <div class="text-center">
            <img style={{width:"100%",paddingBottom:"10px"}} src="assets/img/slide/video12.jpeg" alt="video12" />
            <h1> Video </h1>
            </div>
            <div class='col'>
              {/* <div className='details1'> */}
              <div>
                {/* <video width="202.5" height="360" controls>
                  <source src="assets/img/slide/video.mp4" type="video/mp4" />
                </video> */}
                {/* <div class="col-md-6"> */}
                  {/* <video width="202.5" height="360" controls style={{margin:"20px"}}>
                  <source src="assets/img/slide/video2.mp4" type="video/mp4" />
                </video>
                <video width="202.5" height="360" controls style={{margin:"20px"}}>
                  <source src="assets/img/slide/video2.mp4" type="video/mp4" />
                </video> */}
                {/* <video width="202.5" height="360" controls style={{margin:"20px"}}>
                  <source src="assets/img/slide/video1.mp4" type="video/mp4" />
                </video> */}
                {/* <video width="202.5" height="360" controls style={{margin:"20px"}}>
                  <source src="assets/img/slide/video4.mp4" type="video/mp4" />
                </video> */}
                {/* <a href="https://www.youtube.com/live/o6YFBz4-4X0?feature=share" target={"_blank"}>
                   <iframe style={{ width: "202.5", height: "250px" }} src="https://www.youtube.com/embed/o6YFBz4-4X0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></a>
                    <a href="https://www.youtube.com/live/o6YFBz4-4X0?feature=share" target={"_blank"}>
                   <iframe style={{ width: "202.5", height: "250px" }} src="https://www.youtube.com/embed/o6YFBz4-4X0" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></a>
                    */}
                </div>

                <div>
               <h1> Shree Shardapith math Dwraka | Latest Video </h1>  
                {/* <h4> चातुर्मास्यव्रतानुष्ठान जगद्गुरु,शंकराचार्य जी के आशीर्वचन श्रीमद्भागवत गीता, द्वारका गुजरात।  </h4>  */}
                {/* <div className='row'>

                <div className='col-md-4'>
                  <a className='abc' href="https://youtu.be/Ll5B33fO-9s" target={"_blank"} > 
                   <iframe style={{ width: "202.5", height: "250px" }} src="https://www.youtube.com/embed/Ll5B33fO-9s?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"> भाग-2</iframe>
                   <h5>भाग-1</h5>
                 </a>
                 </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://youtu.be/HUDNKhksvL0" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/HUDNKhksvL0?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-2 </h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/NczV47lUQXo?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/NczV47lUQXo?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-3</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/xXF1WS61OFI?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/xXF1WS61OFI?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-4</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/o6YFBz4-4X0" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/o6YFBz4-4X0?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-5</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/fKSYfQcGFvM?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/fKSYfQcGFvM?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-6</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/JYApklg2E_Q?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/JYApklg2E_Q?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-7</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/qxTXstJHhS0?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/qxTXstJHhS0?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-8</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/cBh9T9-PcO4?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/cBh9T9-PcO4?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5> भाग-9</h5>                  
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/BLwmI-sXLO4?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/BLwmI-sXLO4?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-10</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/eAtx5svCf3c?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/eAtx5svCf3c?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-11</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/KfRTMStpgEM?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/KfRTMStpgEM?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-12</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/3Jyv7CZhy4w?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/3Jyv7CZhy4w?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-13</h5>
                    </a>
                  </div>
                  <div className='col-md-4'>
                    <a className='abc' href="https://www.youtube.com/live/3AZVv_5gAz8?feature=share" target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }}  src="https://www.youtube.com/embed/3AZVv_5gAz8?autoplay=1&mute=1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-14</h5>
                    </a>
                  </div>
                  

                </div> */}
                </div>
              </div>
              

            {/* </div> */}
            <div class='row'>
              
              {/* <div class="col-md-6">
                <video width="202.5" height="460" controls>
                  <source src="assets/img/slide/video1.mp4" type="video/mp4" />
                </video>
              </div> */}
              {
              video.map((hiredeve,i)=> (
                <React.Fragment key={i}>
                  {/* <div className='city1'> */}
              {/* <div className='details1'>
              <ReactPlayer  url={"/upload/"+hiredeve.video} controls={true}   
              // width='100%' height='100%' 
                 /> 
             </div>  */}
             <div className='col-md-4'>
                    {/* <a className='abc' href={video.video} target={"_blank"}> 
                    <iframe style={{ width: "202.5", height: "250px" }} src={video.video} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    <h5>भाग-14</h5>
                    </a>
                     */}
                     <ReactPlayer  url={hiredeve.video} controls={true}  width='70%' height='90%' /> 
                  </div>
                  
              {/* </div> */}
              
                </React.Fragment>
              ))
        } 
           </div>
          </div>
        </section>
      </section>
    </div>
  );
}


// 🙏जय द्वारकाधीस,🙏
// आज भारत के ग्रह मंत्री श्री अमित शाह जी का द्वारका आगमान हुआ। उन्होंने भगवान द्वारकाधीस के दर्शन कर
// अनंत श्रीविभूषित पश्चिमाम्नाय द्वारका शारदापीठाधीश्वर जगद्गुरु शंकराचार्य  स्वामी श्री सदानन्द सरस्वती जी महाराज श्री से आर्शीवाद प्राप्त किया।